import React, { useCallback, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";
import PageHeader from "../../../../../atoms/PageHeader";
import Search from "../../../../../molecules/search";
import CustomButton from "../../../../../atoms/CustomButton";
import CustomDatagrid from "../../../../../molecules/CustomDatagrid";
import Pagination from "../../../../../molecules/Pagination";
import Modal from "../../../../../organisms/modals/Modal";
import DeleteModal from "../../../../../organisms/modals/DeleteModal";
import Form from "./Form";
import { deleteData, getData } from "../../../../../../services";
import { useDelete } from "../../../../../../hooks/useDelete";
import Edit from "../../../../../icons/Edit";
import View from "../../../../../icons/View";
import { capitalize } from "../../../../../../helpers/capitalize";
import NoContentIcon from "../../../../../icons/NocontentIcon";
import Delete from "../../../../../icons/Delete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/toast";

const UserManagement = ({ is_create }) => {
  const [cookies] = useCookies(["t", "uid", "role", "un"]);
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalData: null,
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });

  const Role = atob(cookies?.role);

  const getUserData = useCallback(async () => {
    try {
      // setLoading(true);
      const res = await getData({
        endpoint: "user/getAllUsers",
        params: {
          search_query: searchedValue,
          page: currentPage,
          page_limit: itemsPerPage,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res?.data);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.t, currentPage, itemsPerPage]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const totalItems = tableData?.total_record;

  const columns = [
    {
      field: "name",
      headerName: "User Name",
      flex: 1,
      sortable: true,
      renderCell: (params) => capitalize(params.value),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      sortable: true,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        let statusText = "";
        let statusClass = "";

        if (params.row.status === "Active") {
          statusText = "Active";
          statusClass = "text-success fw-bold";
        } else if (params.row.status === "Inactive") {
          statusText = "Inactive";
          statusClass = "text-error fw-bold";
        } else if (params.row.status === "Pending") {
          statusText = "Pending";
          statusClass = "text-warning fw-bold"; // Adjust color or style for pending status
        }
        return (
          <>
            <span className={statusClass}>{statusText}</span>
          </>
        );
      },
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   flex: 1,
    //   sortable: true,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {Role === "Admin" && (
    //           <div>
    //             {params.row.user_id !== cookies.uid && (
    //               <>
    //                 <span
    //                   className="me-2 pointer"
    //                   onClick={() => {
    //                     handleEdit(params.row);
    //                   }}
    //                 >
    //                   <Edit />
    //                 </span>
    //                 <span
    //                   className="me-2 pointer"
    //                   onClick={() =>
    //                     handleDeleteModal(params.row.user_id, params.row.name)
    //                   }
    //                 >
    //                   <Delete color="black" />
    //                 </span>
    //               </>
    //             )}
    //           </div>
    //         )}
    //       </>
    //     );
    //   },
    // },
    ...(Role === "Admin"
      ? [
          {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            sortable: true,
            renderCell: (params) => {
              return (
                <>
                  {params.row.user_id !== cookies.uid && (
                    <>
                      <span
                        className="me-2 pointer"
                        onClick={() => {
                          handleEdit(params.row);
                        }}
                      >
                        <Edit />
                      </span>
                      <span
                        className="me-2 pointer"
                        onClick={() =>
                          handleDeleteModal(params.row.user_id, params.row.name)
                        }
                      >
                        <Delete color="black" />
                      </span>
                    </>
                  )}
                </>
              );
            },
          },
        ]
      : []),
  ];

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  // add , edit , delete
  const openModal = (title, data) => {
    const modalId = uuidv4();
    setModalState((prevState) => ({
      ...prevState,
      modalId,
      isModalOpen: true,
      title,
      modalData: data,
    }));
  };

  const closeModal = () => {
    setModalState((prevState) => ({ ...prevState, isModalOpen: false }));
  };

  const handleEdit = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      modalId: data.user_id,
      modalData: data,
      isModalOpen: true,
      title: "Edit User",
    }));
  };

  const deleteUser = useDelete();

  // const handleDeleteModal = (data, name) => {
  //   setModalState((prevState) => ({
  //     ...prevState,
  //     isDeleteModalOpen: true,
  //     modalId: data.id,
  //     name: name,
  //   }));
  // };
  const handleDeleteModal = (user_id, name) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: user_id, // Correct user_id being set here
      name: name,
    }));
  };

  // const handleDeletion = async (id) => {
  //   try {
  //     // await deleteUser("user/deleteUserById", "User", {
  //     //   id: modalState.modalId,
  //     // });
  //     const res = await deleteData({
  //       endpoint: `user/deleteUserById`,
  //       params: {
  //         userId: "65679087",
  //       },
  //       token: cookies.t,
  //     });
  //     console.log("res->", res);
  //     getUserData();
  //     setModalState((prevState) => ({
  //       ...prevState,
  //       isDeleteModalOpen: false,
  //     }));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const handleDeletion = async () => {
    try {
      const res = await deleteData({
        endpoint: `user/deleteUserById`,
        params: {
          userId: modalState.modalId, // Correct user_id being used here
        },
        token: cookies.t,
      });
      if (res?.data?.status) {
        toast.success("User Deleted Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
      } else {
        toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      }
      getUserData(); // Refresh the data after successful deletion
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="d-flex space-between">
        <PageHeader title="User Management" type="small" />
        <div className="d-flex gap-3">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />

          {Role === "Admin" && (
            <CustomButton
              type="btn-primary"
              text="Add User"
              handleClick={() => openModal("Add User")}
            />
          )}
        </div>
      </div>

      {tableData?.data && tableData.data.length > 0 ? (
        <>
          <CustomDatagrid
            getRowId={(row) => row.user_id}
            rows={tableData?.data || []}
            columns={columns}
            tHeight="50vh"
          />
          {totalItems > 10 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={tableData?.total_record}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <div>
            <NoContentIcon />
            <div
              className="graph-heading"
              style={{
                fontSize: "18px",
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              No Records Found
            </div>
          </div>
        </div>
      )}
      {modalState?.isModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isModalOpen}
          onClose={closeModal}
          title={modalState.title}
          extrastyle={{
            overflow: "visible",
            cssText: "overflow: visible !important",
          }}
          width="600px"
        >
          <Form
            onClose={closeModal}
            id={modalState.modalId}
            initialValues={
              modalState.title === "Edit User" ? modalState.modalData : null
            }
            refetchData={getUserData}
          />
        </Modal>
      )}

      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="User"
          onClick={handleDeletion}
        />
      )}
    </>
  );
};

export default UserManagement;
