import { useState } from "react";
import PageCard from "../../atoms/PageCard";

const None = () => {
  const [form, setForm] = useState({
    title: "",
    description: "",
    items: [
      {
        title: "",
        count: 1,
        options: ["option 1"],
      },
    ],
  });

  const addOption = (itemIndex) => {
    const updatedItems = [...form.items];
    updatedItems[itemIndex].count += 1;
    updatedItems[itemIndex].options.push(
      `option ${updatedItems[itemIndex].count}`
    );
    setForm({ ...form, items: updatedItems });
  };

  const removeOption = (itemIndex, optionIndex) => {
    const updatedItems = [...form.items];
    updatedItems[itemIndex].count -= 1;
    updatedItems[itemIndex].options.splice(optionIndex, 1);
    setForm({ ...form, items: updatedItems });
  };

  const updateOption = (itemIndex, optionIndex, value) => {
    const updatedItems = [...form.items];
    updatedItems[itemIndex].options[optionIndex] = value;
    setForm({ ...form, items: updatedItems });
  };

  const copyItem = (itemIndex) => {
    const updatedItems = [...form.items];
    const newItem = {
      ...form.items[itemIndex],
      options: [...form.items[itemIndex].options],
    };
    updatedItems.splice(itemIndex + 1, 0, newItem);
    setForm({ ...form, items: updatedItems });
  };

  const deleteItem = (itemIndex) => {
    const updatedItems = [...form.items];
    updatedItems.splice(itemIndex, 1);
    setForm({ ...form, items: updatedItems });
  };

  const updateItemTitle = (itemIndex, value) => {
    const updatedItems = [...form.items];
    updatedItems[itemIndex].title = value;
    setForm({ ...form, items: updatedItems });
  };

  return (
    <>
      <PageCard>
        <div className="d-flex flex-column">
          <label>Title</label>
          <input
            className="title-form mb-2"
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
            type="text"
          />
        </div>
        <div className="d-flex flex-column">
          <label>Description</label>
          <input
            className="title-form mb-2"
            value={form.description}
            onChange={(e) => setForm({ ...form, description: e.target.value })}
            type="text"
          />
        </div>
        {form.items.map((item, itemIndex) => (
          <div key={itemIndex} className="bg:red mb-3 p-2 border">
            <input
              className="title-form mb-2"
              value={item.title}
              onChange={(e) => updateItemTitle(itemIndex, e.target.value)}
              type="text"
            />
            <div className="d-flex flex-column">
              {Array.from({ length: item.count }).map((_, optionIndex) => (
                <div
                  key={optionIndex}
                  className="d-flex gap-2 align-items-center"
                >
                  <input
                    value={item.options[optionIndex]}
                    onChange={(e) =>
                      updateOption(itemIndex, optionIndex, e.target.value)
                    }
                  />
                  {optionIndex + 1 === item.count ? (
                    <div
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        fontSize: "40px",
                      }}
                      onClick={() => addOption(itemIndex)}
                    >
                      +
                    </div>
                  ) : (
                    <div
                      style={{
                        color: "red",
                        cursor: "pointer",
                        fontSize: "40px",
                      }}
                      onClick={() => removeOption(itemIndex, optionIndex)}
                    >
                      -
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="d-flex gap-2 mt-2">
              <div
                style={{
                  color: "blue",
                  cursor: "pointer",
                  fontSize: "20px",
                }}
                onClick={() => copyItem(itemIndex)}
              >
                Copy
              </div>
              <div
                style={{
                  color: "red",
                  cursor: "pointer",
                  fontSize: "20px",
                }}
                onClick={() => deleteItem(itemIndex)}
              >
                Delete
              </div>
            </div>
          </div>
        ))}
      </PageCard>
    </>
  );
};

export default None;
