import React from "react";
import PageHeader from "../../../atoms/PageHeader";
import styles from "./styles.module.scss";
import InfoCard from "../../../atoms/InfoCard";

import Business from "../../../icons/Business";
import Payment from "../../../icons/Payment";
import Email from "../../../icons/Email";
import Message from "../../../icons/Message";

const Home = () => {
  return (
    <>
      <PageHeader title="Home" />
      <div className={styles.info_card_container}>
        <InfoCard
          color="#B1E5FC"
          title="Total Businesses"
          data={1000}
          svgIcon={<Business />}
          isSvg

          // data={data?.total_company || 0}
        />
        <InfoCard
          color="#FFD88D"
          title="Total Revenue"
          data={1000}
          isSvg
          svgIcon={<Payment />}

          // data={data?.total_company || 0}
        />
        <InfoCard
          color="#FFB7B7"
          isSvg
          svgIcon={<Email />}
          title="Total Emails"
          data={1000}

          // data={data?.total_company || 0}
        />
        <InfoCard
          color="#B5E4CA"
          title="Total SMS"
          isSvg
          svgIcon={<Message />}
          data={1000}
          // data={data?.total_company || 0}
        />
      </div>
    </>
  );
};

export default Home;
