import { Formik } from "formik";
import PageCard from "../../../../atoms/PageCard";
import PageHeader from "../../../../atoms/PageHeader";
import SelectDropdown from "../../../../molecules/Dropdown";
import CustomButton from "../../../../atoms/CustomButton";
import { useFetcher, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { deleteData, getData, postFormData } from "../../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import CustomDropzone from "../../../../molecules/uploadFile";
import FinancialDataTable from "../../../../organisms/viewDataTable";
import {
  auditStatusOptions,
  periodTypeOptions,
  sheetData,
} from "../../../../../helpers/staticData";
import BackBtn from "../../../../atoms/BackBtn";

const AddFinancialData = () => {
  const { id } = useParams();

  const [cookies] = useCookies(["t"]);

  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [isLoadingDel, setIsLoadingDel] = useState(false); // Add loading state
  const [requiredAuditStatus, setRequiredAuditStatus] = useState([]);
  const [requiredPeriodType, setRequiredPeriodType] = useState([]);
  const [requiredSheetData, setRequiredSheetData] = useState([]);
  const [requiredYearData, setRequiredYearData] = useState([]);

  const validationSchema = Yup.object().shape({
    sheetType: Yup.string().required("Sheet Type is Required"),
    periodType: Yup.string().required("Period Type is Required"),
    auditStatus: Yup.string().required("Audit Status is Required"),
  });

  const [companyData, setCompanyData] = useState();
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [sheetType, setSheetType] = useState(null);
  const [auditStatus, setAuditStatus] = useState(null);
  const [periodType, setPeriodType] = useState(null);
  const [year, setYears] = useState(null);
  const [currency, setCurrency] = useState();
  const [units, setUnits] = useState();

  const initialValues = {
    sheetType: "",
    periodType: "",
    auditStatus: "",
    years: [],
  };

  useEffect(() => {
    (async () => {
      const res = await getData({
        endpoint: "company/getCompanyListByCompanyId",
        token: cookies.t,
        params: { companyId: id },
      });
      setCompanyData(res?.data);

      handleAuditOptions(res?.data);
    })();
  }, []);

  const handleAuditOptions = (option) => {
    const matchingPeriodOptions = auditStatusOptions?.filter((item) =>
      option?.availableAuditStatuses?.includes(item.value),
    );

    const temp = matchingPeriodOptions.map((item) => ({
      ...item,
      data: option?.availableData[item?.value],
    }));

    // You can return or log the enhanced options depending on your use case
    setRequiredAuditStatus(temp);
  };

  const handlePeriodOption = (option) => {
    if (option) {
      const dataKeys = Object?.keys(option?.data);

      const matchingPeriodOptions = periodTypeOptions
        ?.filter((item) => dataKeys.includes(item?.value))
        .map((item) => ({
          ...item,
          data: option.data[item.value], // Add the years array to each matching item
        }));
      setRequiredPeriodType(matchingPeriodOptions);
    } else {
      setRequiredPeriodType([]);
    }
  };

  const handleYears = (option) => {
    const temp = option?.data?.map((item) => ({ value: item, label: item }));
    setRequiredYearData(temp);
  };

  const handleSheetData = async (option, values) => {
    if (values?.auditStatus && values?.periodType && option) {
      const res = await getData({
        endpoint: "company/getAvailableSheetType",
        token: cookies.t,
        params: {
          companyId: id,
          auditStatus: values?.auditStatus,
          periodType: values?.periodType,
          years: option,
        },
      });

      const temp = res?.data?.availableSheetType;

      const matching = sheetData?.filter((item) => temp.includes(item?.value));

      setRequiredSheetData(matching);
    } else {
      setRequiredSheetData([]);
      return;
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true); // Set loading to true when API call starts
    const payload = {
      sheetType: values.sheetType,
      periodType: values.periodType,
      auditStatus: values.auditStatus,
      years: values?.years,
    };

    try {
      const res = await getData({
        endpoint: "company/getFilteredFinancialData",
        params: {
          companyId: id,
          sheetType: values.sheetType,
          periodType: values.periodType,
          auditStatus: values.auditStatus,
          yearsArray: values.years,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res?.data);
        setAuditStatus(res?.data?.auditStatus);
        setPeriodType(res?.data?.periodType);
        setSheetType(res?.data?.sheetType);
        setYears(values.years);
        setCurrency(res?.data?.currency);
        setUnits(res?.data?.units);
        setShowTable(true);
      }

      if (res?.status) {
        toast.success("Financial Data Fetched Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
      } else {
        toast.error(res?.data?.message || "Failed to add financial data", {
          style: errorStyles,
          duration: 1000,
        });
      }
    } catch (error) {
      console.error("Error adding financial data:", error);
      toast.error("An error occurred while adding financial data", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsLoading(false); // Set loading to false when API call completes
    }
  };

  const handleDelete = async (values) => {
    setIsLoadingDel(true); // Set loading to true when API call starts
    const payload = {
      sheetType: values.sheetType,
      periodType: values.periodType,
      auditStatus: values.auditStatus,
      years: values?.years,
    };

    try {
      const res = await deleteData({
        endpoint: "company/deleteFinancialDataByYears",
        params: {
          companyId: id,
          sheetType: values.sheetType,
          periodType: values.periodType,
          auditStatus: values.auditStatus,
          yearsArray: values.years,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res?.data);
        setAuditStatus(res?.data?.auditStatus);
        setPeriodType(res?.data?.periodType);
        setSheetType(res?.data?.sheetType);
        setYears(values.years);
        setShowTable(true);
      }

      if (res?.status) {
        toast.success("Financial Data Deleted Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
      } else {
        toast.error(res?.data?.message || "Failed to delete financial data", {
          style: errorStyles,
          duration: 1000,
        });
      }
    } catch (error) {
      console.error("Error deleting financial data:", error);
      toast.error("An error occurred while deleting financial data", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsLoadingDel(false); // Set loading to false when API call completes
    }
  };

  const handleCancel = async (
    companyId,
    auditStatus,
    periodType,
    extractedYears,
    sheetType,
  ) => {
    console.log(
      "Vlaues Entering :",
      companyId,
      auditStatus,
      periodType,
      extractedYears,
      sheetType,
    );

    try {
      console.log(
        "companyId,auditStatus,periodType,year,sheetType",
        companyId,
        auditStatus,
        periodType,
        extractedYears,
        sheetType,
      );
      const res = await getData({
        endpoint: "company/getFilteredFinancialData",
        params: {
          companyId: companyId,
          sheetType: sheetType,
          periodType: periodType,
          auditStatus: auditStatus,
          yearsArray: extractedYears,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res?.data);
        setAuditStatus(res?.data?.auditStatus);
        setPeriodType(res?.data?.periodType);
        setSheetType(res?.data?.sheetType);
        setShowTable(true);
      }

      // if (res.status) {
      //   toast.success(res.message, {
      //     style: confimationStyles,
      //     duration: 1000,
      //   });
      // } else {
      //   toast.error(res.message, { style: errorStyles, duration: 1000 });
      // }
    } catch (error) {
      console.error("Error canceling changes:", error);
    }
  };

  return (
    <>
      <div style={{ width: "20px", marginBottom: "10px" }}>
        <BackBtn />
      </div>
      <PageHeader title="Financial Data" />
      <PageCard>
        <Formik
          key={Math.random}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            isSubmitting,
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => (
            <>
              <div>
                <div className="row">
                  <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="auditStatus">Audit Status</label>
                    <SelectDropdown
                      data={requiredAuditStatus}
                      placeholder="Select Audit Status"
                      onChange={(option) => {
                        setFieldValue("auditStatus", option?.value);
                        handlePeriodOption(option);
                      }}
                      format="all"
                      isClearable={true}
                      selectedValue={values.auditStatus}
                      onClear={() => {
                        setFieldValue("auditStatus", "");
                        setFieldValue("periodType", "");
                        setFieldValue("sheetType", "");
                        setFieldValue("years", []);
                        setRequiredYearData([]);
                        setRequiredPeriodType([]);
                        setRequiredSheetData([]);
                      }}
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="periodType">Period Type</label>
                    <SelectDropdown
                      data={requiredPeriodType}
                      placeholder="Select Period Type"
                      onChange={(option) => {
                        setFieldValue("periodType", option?.value);
                        handleYears(option);
                      }}
                      format="all"
                      isClearable={true}
                      selectedValue={values.periodType}
                      onClear={() => {
                        setFieldValue("periodType", "");
                        setFieldValue("sheetType", "");
                        setFieldValue("years", []);
                      }}
                    />
                  </div>

                  <div className="col-lg-3 col-md-6 mt-3">
                    {/* <label htmlFor="Year">Year</label> */}
                    <label htmlFor="Year">
                      {!values.periodType && "Period"}
                      {values.periodType === "yearly" && "Year"}
                      {values.periodType === "quarterly" && "Quarter"}
                      {values.periodType === "monthly" && "Month"}
                      {values.periodType === "halfYearly" && "Half-Year"}
                    </label>
                    <SelectDropdown
                      data={requiredYearData}
                      // placeholder="Select Year"
                      placeholder={`Select ${
                        values.periodType === "yearly"
                          ? "Year"
                          : values.periodType === "quarterly"
                          ? "Quarter"
                          : values.periodType === "monthly"
                          ? "Month"
                          : values.periodType === "halfYearly"
                          ? "Half-Year"
                          : "Period"
                      }`}
                      onChange={(option) => {
                        setFieldValue("years", option);
                        handleSheetData(option, values);
                      }}
                      multiSelect
                      isClearable={true}
                      selectedValue={values.years}
                      onClear={() => {
                        setFieldValue("years", []);
                        setFieldValue("sheetType", []);
                      }}
                      onClose={false}
                    />
                    {touched.years && errors.years && (
                      <div style={{ color: "red", marginTop: "0.5rem" }}>
                        {errors.years}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="sheetType">Sheet Type</label>
                    <SelectDropdown
                      data={requiredSheetData}
                      placeholder="Select Sheet Type"
                      onChange={(option) => setFieldValue("sheetType", option)}
                      isClearable={true}
                      selectedValue={values.sheetType}
                      onClear={() => setFieldValue("sheetType", "")}
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-lg-12 col-md-12">
                    <CustomButton
                      type="btn-primary"
                      // disabled={isLoading}
                      disabled={isLoading || !isValid || !dirty}
                      iconRequired={false}
                      handleClick={() => handleSubmit(values)}
                    >
                      Submit
                      {isLoading && (
                        <div
                          className="spinner-border spinner-border-sm text-light ms-3"
                          role="status"
                        ></div>
                      )}
                    </CustomButton>
                    {/* <CustomButton
                      type="btn-primary"
                      disabled={isLoading}
                      iconRequired={false}
                      handleClick={() => handleDelete(values)}
                    >
                      Delete
                      {isLoadingDel && (
                        <div
                          className="spinner-border spinner-border-sm text-light ms-3"
                          role="status"
                        ></div>
                      )}
                    </CustomButton> */}
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </PageCard>

      {showTable && (
        <PageCard classes="mt-3">
          <FinancialDataTable
            financialData={tableData}
            handleCancel={handleCancel}
            sheetType={sheetType}
            periodType={periodType}
            auditStatus={auditStatus}
            companyId={id}
            year={year}
            currencyNew={currency}
            unitsNew={units}
            view={true}
          />
        </PageCard>
      )}
    </>
  );
};

export default AddFinancialData;
