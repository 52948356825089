export const fetchPincodeService = async (pincode) => {
  try {
    const response = await fetch(
      `https://api.postalpincode.in/pincode/${pincode}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();

    if (
      Array.isArray(data) &&
      data.length > 0 &&
      data[0].Status === "Success"
    ) {
      // Pincode is valid, set the data
      const resData = data[0].PostOffice[0];
      return resData;
    } else {
      console.error("Invalid pincode or other error.");
      return null; // You might want to return something here for error cases.
    }
  } catch (error) {
    console.error("Error fetching Pincode data:", error);
    return null; // Handle errors and return something appropriate for error cases.
  }
};
