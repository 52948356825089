import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import PageCard from "../../../../atoms/PageCard";
import PageHeader from "../../../../atoms/PageHeader";
import InputLayout from "../../../../atoms/InputLayout/InputLayout";
import SelectDropdown from "../../../../molecules/Dropdown";
import {
  countryCodeData,
  countryNameData,
} from "../../../../../helpers/countryCodes";
import Error from "../../../../atoms/Error";
import CustomButton from "../../../../atoms/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { getData, patchData, postData } from "../../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import Add from "../../../../icons/Add";
import Delete from "../../../../icons/Delete";
import { DateSelector } from "../../../../molecules/MonthYearSelector/index";
import { fetchPincodeService } from "../../../../../helpers/pinCodeService.js";
import { stateNameslist } from "../../../../../helpers/indianStates.js";
import BackBtn from "../../../../atoms/BackBtn/index.jsx";
import axios from "axios";
import { capitalizeWords } from "../../../../../helpers/capitalize.js";
import Modal from "../../../../organisms/modals/Modal.jsx";

const AddEmployee = ({ type = "add" }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [isSaving, setIsSaving] = useState(false);
  const [cookies] = useCookies(["t"]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [selectedSecCountryCode, setSelectedSecCountryCode] = useState(null);
  const [showFinancialDataUpload, setShowFinancialDataUpload] = useState(false); // New state for financial data upload section
  const [rows, setRows] = useState([0]);
  const [thousandsRates, setThousandsRates] = useState({});

  const currencies = [
    { value: "INR", label: "INR" },
    { value: "USD", label: "USD" },
    { value: "EUR", label: "EUR" },
    { value: "GBP", label: "GBP" },
    { value: "AED", label: "AED" },
  ];

  // const currencies = Object.keys(thousandsRates)?.map((key) => ({
  //   value: key,
  //   label: key,
  // }));

  useEffect(() => {
    const fetchCurrencyConversionRates = async () => {
      try {
        const response = await axios.get(
          "https://open.er-api.com/v6/latest/INR",
        );

        if (response) {
          setThousandsRates(response?.data?.rates);
        }
      } catch (error) {
        console.error("Error fetching currency conversion rates:", error);
      }
    };

    fetchCurrencyConversionRates();
  }, []);

  const [registeredOffice, setRegisteredOffice] = useState({
    address: "",
    city: "",
    state: "",
    country: "",
  });

  const [businessOffice, setBusinessOffice] = useState({
    address: "",
    city: "",
    state: "",
    country: "",
  });

  const [isSameAddress, setIsSameAddress] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      const categoryList = await getData({
        endpoint: "category/getCategoryList", // Replace with your actual endpoint
        token: cookies.t, // Pass the token if needed
      });
      console.log("categoryList", categoryList);

      // Assuming each category object contains `name` and you want to set `value` and `label`
      const formattedCategories = categoryList?.map((category) => ({
        value: category.name,
        label: category.name,
      }));

      console.log("categoryList", categoryList);
      console.log("formattedCategories", formattedCategories);

      setCategories(formattedCategories);
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Handle error (e.g., show a message to the user)
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [cookies.t]); // Empty dependency array to run once on mount

  console.log("categories----------->", categories);
  const handleCategoryAdded = async (event) => {
    event.preventDefault();

    if (!newCategory) return; // Do nothing if the input is empty

    try {
      // Use postData function for API call
      const { data: addedCategory } = await postData({
        endpoint: "category/addCategory",
        data: { name: newCategory },
        token: cookies.t, // Pass the token if needed
      });

      if (addedCategory) {
        toast.success("Category Added Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
      }

      // Refresh category list
      fetchCategories();

      // Assuming the response includes the added category in `addedCategory.category`
      setCategories((prev) => [
        ...prev,
        { value: addedCategory.category, label: addedCategory.category },
      ]);

      setNewCategory(""); // Clear the input field
      setIsModalOpen(false); // Close the modal
    } catch (error) {
      console.error("Error adding category:", error);
      // Handle error (e.g., show a message to the user)
    }
  };

  // const handleToggle = (setFieldValue) => {
  //   setIsSameAddress(!isSameAddress);
  //   if (!isSameAddress) {
  //     setBusinessOffice({ ...registeredOffice });
  //     setFieldValue("businessOffice", { ...registeredOffice });
  //   }
  // };
  const [selectedState, setSelectedState] = useState(null);
  const handleSelectedCountry = (itemId, setFieldValue) => {
    setSelectedCountry(itemId);
    if (itemId !== "India") {
      setFieldValue("pincode", ""); // Clear pincode
      setFieldValue("state", ""); // Clear state
      setFieldValue("city", ""); // Clear city
    }
  };

  const handleSelectedState = (itemId) => {
    setSelectedState(itemId);
  };
  const handlePincodeChange1 = async (setFieldValue, e) => {
    const pincode = e.target.value;
    setFieldValue("registeredOffice.pincode", pincode);

    if (pincode.length === 6) {
      // Assuming pincode length is 6
      const pincodeData = await fetchPincodeService(pincode);
      if (pincodeData) {
        setFieldValue("registeredOffice.city", pincodeData.District);
        setFieldValue("registeredOffice.state", pincodeData.State);
      } else {
        // Handle case when pincode data is not available
        setFieldValue("registeredOffice.city", "");
        setFieldValue("registeredOffice.state", "");
      }
    } else {
      setFieldValue("registeredOffice.city", "");
      setFieldValue("registeredOffice.state", "");
    }
  };
  const handlePincodeChange2 = async (setFieldValue, e) => {
    const pincode = e.target.value;
    setFieldValue("businessOffice.pincode", pincode);

    if (pincode.length === 6) {
      // Assuming pincode length is 6
      const pincodeData = await fetchPincodeService(pincode);
      if (pincodeData) {
        setFieldValue("businessOffice.city", pincodeData.District);
        setFieldValue("businessOffice.state", pincodeData.State);
      } else {
        // Handle case when pincode data is not available
        setFieldValue("businessOffice.city", "");
        setFieldValue("businessOffice.state", "");
      }
    } else {
      setFieldValue("businessOffice.city", "");
      setFieldValue("businessOffice.state", "");
    }
  };

  // const handleToggle = (setFieldValue, values) => {
  //   setIsSameAddress(!isSameAddress);
  //   if (!isSameAddress) {
  //     setFieldValue("businessOffice.address", values.registeredOffice.address);
  //     setFieldValue("businessOffice.city", values.registeredOffice.city);
  //     setFieldValue("businessOffice.state", values.registeredOffice.state);
  //     setFieldValue("businessOffice.country", values.registeredOffice.country);
  //     setFieldValue("businessOffice.pincode", values.registeredOffice.pincode);
  //   } else {
  //     setFieldValue("businessOffice.address", "");
  //     setFieldValue("businessOffice.city", "");
  //     setFieldValue("businessOffice.state", "");
  //     setFieldValue("businessOffice.country", "");
  //     setFieldValue("businessOffice.pincode", "");
  //   }
  // };
  // const handleToggle = (setFieldValue, values) => {
  //   setIsSameAddress(!isSameAddress);
  //   if (!isSameAddress) {
  //     // Copying registered office details to business office
  //     setFieldValue("businessOffice", { ...values.registeredOffice });
  //   }
  // };
  const handleToggle = (setFieldValue, values) => {
    setIsSameAddress((prevState) => {
      const newState = !prevState;
      if (newState) {
        // Copying registered office details to business office
        setFieldValue("businessOffice", { ...values.registeredOffice });
      } else {
        // Clearing business office fields
        setFieldValue("businessOffice", {
          address: "",
          city: "",
          state: "",
          country: "",
          pincode: "",
        });
      }
      return newState;
    });
  };

  const handleRegisteredOfficeChange = (
    field,
    value,
    setFieldValue,
    values,
  ) => {
    setFieldValue(`registeredOffice.${field}`, value);
    if (isSameAddress) {
      setFieldValue(`businessOffice.${field}`, value);
    }
  };

  const handlePincodeChange = async (setFieldValue, e, addressType) => {
    const pincode = e.target.value;
    setFieldValue(`${addressType}.pincode`, pincode);

    if (pincode.length === 6) {
      const pincodeData = await fetchPincodeService(pincode);
      if (pincodeData) {
        setFieldValue(`${addressType}.city`, pincodeData.District);
        setFieldValue(`${addressType}.state`, pincodeData.State);
        if (isSameAddress && addressType === "registeredOffice") {
          setFieldValue("businessOffice.pincode", pincode);
          setFieldValue("businessOffice.city", pincodeData.District);
          setFieldValue("businessOffice.state", pincodeData.State);
        }
      } else {
        setFieldValue(`${addressType}.city`, "");
        setFieldValue(`${addressType}.state`, "");
        if (isSameAddress && addressType === "registeredOffice") {
          setFieldValue("businessOffice.pincode", pincode);
          setFieldValue("businessOffice.city", "");
          setFieldValue("businessOffice.state", "");
        }
      }
    } else {
      setFieldValue(`${addressType}.city`, "");
      setFieldValue(`${addressType}.state`, "");
      if (isSameAddress && addressType === "registeredOffice") {
        setFieldValue("businessOffice.pincode", pincode);
        setFieldValue("businessOffice.city", "");
        setFieldValue("businessOffice.state", "");
      }
    }
  };

  const handleAddressChange = (field, value, type, setFieldValue) => {
    const newAddress = { ...type, [field]: value };
    if (type === registeredOffice) {
      setRegisteredOffice(newAddress);
      if (isSameAddress) {
        setBusinessOffice(newAddress);
        setFieldValue("businessOffice", newAddress);
      }
    } else {
      setBusinessOffice(newAddress);
    }
  };

  const addRow = () => {
    setRows([...rows, rows.length]);
  };

  const deleteRow = (index) => {
    if (rows.length > 1) {
      setRows(rows.filter((_, rowIndex) => rowIndex !== index));
    }
  };

  const [directors, setDirectors] = useState([0]);
  const [shareholders, setShareholders] = useState([0]);

  const addDirectorRow = () => {
    setDirectors([...directors, directors.length]);
  };

  const deleteDirectorRow = (index) => {
    if (directors.length > 1) {
      setDirectors(directors.filter((_, rowIndex) => rowIndex !== index));
    }
  };

  const addShareholderRow = () => {
    setShareholders([...shareholders, shareholders.length]);
  };

  const deleteShareholderRow = (index) => {
    if (shareholders.length > 1) {
      setShareholders(shareholders.filter((_, rowIndex) => rowIndex !== index));
    }
  };

  const validationSchema = Yup.object().shape({
    companyname: Yup.string().required("Name is Required"),
    month: Yup.string().required("Starting Month of Fiscal Year is Required"),
    currency: Yup.string().required("Currency is Required"),

    // cin: Yup.string().required("CIN is Required"),
    // dateofIncorporation: Yup.date().required(
    //   "Date of Incorporation is required",
    // ),
    // countryCode: Yup.string()
    //   .nullable()
    //   .test(
    //     "country-code-and-phone-number",
    //     "Either both Country Code and Contact Number should be provided, or neither",
    //     function (value) {
    //       const { countryCode, contactNumber } = this.parent;
    //       if (
    //         (countryCode && !contactNumber) ||
    //         (!countryCode && contactNumber)
    //       ) {
    //         return false;
    //       }
    //       return true;
    //     },
    //   ),
    // contactNumber: Yup.number().test(
    //   "country-code-and-phone-number",
    //   "Either both Country Code and Contact Number should be provided, or neither",
    //   function (value) {
    //     const { countryCode, contactNumber } = this.parent;
    //     if (
    //       (countryCode && !contactNumber) ||
    //       (!countryCode && contactNumber)
    //     ) {
    //       return false;
    //     }
    //     return true;
    //   },
    // ),
    // secCountryCode: Yup.string()
    //   .nullable()
    //   .test(
    //     "sec-country-code-and-sec-contact-number",
    //     "Either both Alternate Country Code and Alternate Contact Number should be provided, or neither",
    //     function (value) {
    //       const { secCountryCode, secContactNumber } = this.parent;
    //       if (
    //         (secCountryCode && !secContactNumber) ||
    //         (!secCountryCode && secContactNumber)
    //       ) {
    //         return false;
    //       }
    //       return true;
    //     },
    //   ),
    // secContactNumber: Yup.string()
    //   .nullable()
    //   .test(
    //     "sec-country-code-and-sec-contact-number",
    //     "Either both Alternate Country Code and Alternate Contact Number should be provided, or neither",
    //     function (value) {
    //       const { secCountryCode, secContactNumber } = this.parent;
    //       if (
    //         (secCountryCode && !secContactNumber) ||
    //         (!secCountryCode && secContactNumber)
    //       ) {
    //         return false;
    //       }
    //       return true;
    //     },
    //   ),
    // email: Yup.string().email("Invalid email").required("Email is Required"),
  });
  const [formData, setFormData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const getCompanyData = useCallback(async () => {
    try {
      // setLoading(true);
      const res = await getData({
        endpoint: "company/getCompanyById",
        params: {
          companyId: id,
          search_query: searchedValue,
          page: currentPage,
          page_limit: itemsPerPage,
        },
        token: cookies.t,
      });

      if (res) {
        setFormData(res?.data);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.t, currentPage, itemsPerPage, id]);

  useEffect(() => {
    if (type === "edit") {
      getCompanyData();
    }
  }, [getCompanyData, type]);

  const data = formData?.data || {};

  useEffect(() => {
    if (data && data?.isSameAddress) {
      setIsSameAddress(data?.isSameAddress);
    }
  }, [data]);

  const initialValues = {
    companyname: data.name || "",
    currency: data.currency || "",
    month: data.month,
    cin: data.cin || "",
    dateofIncorporation: data.dateIncorporation || "",
    domain: data.domain || "",
    email: data.email || "",
    countryCode: data.phoneCountryCode || "",
    contactNumber: data.phone || "",
    secCountryCode: data.alternatePhoneCountryCode || "",
    secContactNumber: data.alternatePhone || "",
    category: data.category || "",
    gst: data.GST || "",
    registeredOffice: {
      address: data.registeredOffice?.address || "",
      city: data.registeredOffice?.city || "",
      state: data.registeredOffice?.state || "",
      country: data.registeredOffice?.country || "",
      pincode: data.registeredOffice?.pincode || "",
    },
    businessOffice: {
      address: data.businessOffice?.address || "",
      city: data.businessOffice?.city || "",
      state: data.businessOffice?.state || "",
      country: data.businessOffice?.country || "",
      pincode: data.businessOffice?.pincode || "",
    },
    isSameAddress: data.isSameAddress || "",
    directors:
      data.directors?.length > 0
        ? data.directors.map((director) => ({
            DIN_PAN: director.DIN_PAN || "",
            directorName: director.directorName || "",
            dateOfAppointment: director.dateOfAppointment || "",
          }))
        : [
            {
              DIN_PAN: "",
              directorName: "",
              dateOfAppointment: "",
            },
          ],
    shareholders:
      data.shareholders?.length > 0
        ? data.shareholders.map((shareholder) => ({
            shareholderName: shareholder.shareholderName || "",
            folio: shareholder.folio || "",
            dp_id: shareholder.dp_id || "",
            sharesHeldNumber: shareholder.sharesHeldNumber || "",
            classOfShares: shareholder.classOfShares || "",
            percentageOfShareholding:
              shareholder.percentageOfShareholding || "",
          }))
        : [
            {
              shareholderName: "",
              folio: "",
              dp_id: "",
              sharesHeldNumber: "",
              classOfShares: "",
              percentageOfShareholding: "",
            },
          ],
  };

  const handleSubmit = async (values) => {
    setIsSaving(true);
    const transformedData = {
      name: values.companyname
        ? capitalizeWords(values.companyname)
        : values.companyname,
      currency: values.currency,
      cin: values.cin,
      month: values.month,
      dateIncorporation: values.dateofIncorporation,
      domain: values.domain,
      email: values.email,
      phone: values.contactNumber,
      phoneCountryCode: values.countryCode, // Assuming countryCode format is like "+91"
      alternatePhone: values.secContactNumber,
      alternatePhoneCountryCode: values.secCountryCode, // Assuming secCountryCode format is like "+92"
      GST: values.gst,
      category: values.category,
      registeredOffice: values.registeredOffice,
      businessOffice: values.businessOffice,
      isSameAddress: isSameAddress,
      directors: values.directors,
      shareholders: values.shareholders,
    };

    // const res = await postData({
    //   endpoint: "company/createCompany",
    //   token: cookies.t,
    //   data: transformedData,
    // });
    // if (res?.data?.status) {
    //   toast.success("Company Added Successfully", {
    //     style: confimationStyles,
    //     duration: 1000,
    //   });
    //   navigate("/dashboard/company");
    // } else {
    //   toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
    // }
    // setIsSaving(false);
    try {
      // Attempt the API call
      const res = await postData({
        endpoint: "company/createCompany",
        token: cookies.t,
        data: transformedData,
      });

      // Check if the API call was successful
      if (res?.data?.status) {
        toast.success("Company Added Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate("/dashboard/company");
      } else {
        // If the API response contains an error
        const errorMessage =
          res?.data?.message || "Failed to add company. Please try again.";
        toast.error(errorMessage, { style: errorStyles, duration: 1000 });
      }
    } catch (error) {
      // Handle unexpected errors (network issues, server errors, etc.)
      console.error("Error adding company:", error);

      // Show a generic error message
      toast.error(
        "An error occurred while adding the company. Please check your connection and try again.",
        {
          style: errorStyles,
          duration: 1000,
        },
      );
    } finally {
      // Always stop the saving indicator regardless of success or failure
      setIsSaving(false);
    }
  };

  const handleEdit = async (values) => {
    setIsSaving(true);

    const transformedData = {
      name: values.companyname ? capitalizeWords(values.companyname) : "",
      currency: values.currency,
      month: values.month,
      cin: values.cin,
      dateIncorporation: values.dateofIncorporation,
      domain: values.domain,
      email: values.email,
      phone: values.contactNumber,
      phoneCountryCode: values.countryCode, // Assuming countryCode format is like "+91"
      alternatePhone: values.secContactNumber,
      alternatePhoneCountryCode: values.secCountryCode, // Assuming secCountryCode format is like "+92"
      GST: values.gst,
      category: values.category,
      registeredOffice: values.registeredOffice,
      businessOffice: values.businessOffice,
      isSameAddress: isSameAddress,
      directors: values.directors,
      shareholders: values.shareholders,
    };

    // const res = await patchData({
    //   endpoint: "company/updateCompanyById",
    //   params: {
    //     companyId: id,
    //   },
    //   token: cookies.t,
    //   data: transformedData,
    // });
    // if (res?.status) {
    //   toast.success("Company Updated Successfully", {
    //     style: confimationStyles,
    //     duration: 1000,
    //   });
    //   navigate("/dashboard/company");
    // } else {
    //   toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
    // }
    // setIsSaving(false);
    try {
      // Attempt the API call
      const res = await patchData({
        endpoint: "company/updateCompanyById",
        params: {
          companyId: id,
        },
        token: cookies.t,
        data: transformedData,
      });

      // Check if the API call was successful
      if (res?.status) {
        toast.success("Company Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate("/dashboard/company");
      } else {
        // Handle if the response contains an error
        const errorMessage =
          res?.data?.message || "Failed to update company. Please try again.";
        toast.error(errorMessage, { style: errorStyles, duration: 1000 });
      }
    } catch (error) {
      // Handle any unexpected errors (network issues, server errors, etc.)
      console.error("Error updating company:", error);

      // Show a generic error message to the user
      toast.error(
        "An error occurred while updating the company. Please check your connection and try again.",
        {
          style: errorStyles,
          duration: 1000,
        },
      );
    } finally {
      // Always stop the saving indicator, whether success or failure
      setIsSaving(false);
    }
  };

  return (
    <>
      <div style={{ width: "20px", marginBottom: "10px" }}>
        <BackBtn />
      </div>
      <PageHeader title={type === "add" ? "Add Company" : "Edit Company"} />

      <PageCard>
        <Formik
          key={Math.random}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            isSubmitting,
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => (
            <>
              <div className="row">
                <h4>Company Details</h4>
                <InputLayout>
                  <label htmlFor="">
                    Company Name{" "}
                    <span style={{ color: "#be0f00", fontWeight: "bold" }}>
                      *
                    </span>
                  </label>
                  <Field
                    type="text"
                    label="Company Name"
                    name="companyname"
                    placeholder="Company Name"
                    className={`w-100 h-100 ${
                      touched.companyname && errors.companyname
                        ? "error-input"
                        : ""
                    }`}
                    onBlur={handleBlur}
                  />
                  {touched.companyname && errors.companyname && (
                    <Error error={errors.companyname} />
                  )}
                </InputLayout>
                <InputLayout>
                  <label htmlFor="">CIN</label>
                  <Field
                    type="text"
                    label="CIN"
                    name="cin"
                    placeholder="CIN"
                    className={`w-100 h-100 ${
                      touched.cin && errors.cin ? "error-input" : ""
                    }`}
                    onBlur={handleBlur}
                  />
                  {touched.cin && errors.cin && <Error error={errors.cin} />}
                </InputLayout>

                <InputLayout>
                  <label htmlFor="">Date of Incorporation</label>
                  <DateSelector
                    placeholder="Select Date of Incorporation"
                    format="DD/MM/YYYY"
                    onChange={(date) =>
                      setFieldValue("dateofIncorporation", date)
                    }
                    selectedDate={values?.dateofIncorporation}
                    handleBlur={(e) => {
                      setFieldTouched("dateofIncorporation", true);
                      handleBlur(e);
                    }}
                    isError={
                      touched?.dateofIncorporation &&
                      errors?.dateofIncorporation
                    }
                  />
                  {touched.dateofIncorporation &&
                    errors.dateofIncorporation && (
                      <Error error={errors.dateofIncorporation} />
                    )}
                </InputLayout>
              </div>

              <div className="row mt-3">
                <InputLayout>
                  <label htmlFor="domain">Domain</label>
                  <Field
                    type="text"
                    id="domain"
                    name="domain"
                    placeholder="Domain"
                    className={`w-100 h-100 ${
                      touched.domain && errors.domain ? "error-input" : ""
                    }`}
                    onBlur={handleBlur}
                  />
                  {touched.domain && errors.domain && (
                    <Error error={errors.domain} />
                  )}
                </InputLayout>
                <InputLayout>
                  <label htmlFor="">GST</label>
                  <Field
                    type="text"
                    label="GST"
                    name="gst"
                    placeholder="GST"
                    className={`w-100 h-100 ${
                      touched.gst && errors.gst ? "error-input" : ""
                    }`}
                    onBlur={handleBlur}
                  />
                  {touched.gst && errors.gst && <Error error={errors.gst} />}
                </InputLayout>
                <InputLayout>
                  <label htmlFor="">Category</label>

                  <SelectDropdown
                    data={categories}
                    placeholder="Select Category"
                    // onChange={(option) => {
                    //   setFieldValue("category", option?.value);
                    // }}
                    onChange={(option) => {
                      setFieldValue("category", option?.value);

                      // Automatically open modal if the "Other" option is selected
                      if (option?.value === "Other") {
                        setIsModalOpen(true);
                      }
                    }}
                    isClearable={true}
                    format={"all"}
                    selectedValue={values.category}
                    onClear={() => {
                      setFieldValue("category", "");
                    }}
                  />
                  {touched.category && errors.category && (
                    <Error error={errors.category} />
                  )}
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <CustomButton
                      type="btn-primary"
                      // iconRequired={false}
                      handleClick={() => setIsModalOpen(true)}
                      width="200px"
                    >
                      Add New Category
                    </CustomButton>
                  </div>

                  {isModalOpen && (
                    <Modal
                      title="Add New Category"
                      isActive={isModalOpen}
                      onClose={() => setIsModalOpen(false)}
                      width="500px"
                      maxHeight="300px"
                    >
                      <Field
                        type="text"
                        className={`w-100 h-100`}
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                        placeholder="Enter new category"
                        // required
                      />
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <CustomButton
                          type="btn-primary"
                          iconRequired={false}
                          handleClick={handleCategoryAdded}
                        >
                          Submit
                        </CustomButton>
                      </div>
                    </Modal>
                  )}
                </InputLayout>
              </div>

              <div className="row mt-3">
                <InputLayout>
                  <label htmlFor="">Email</label>
                  <Field
                    type="text"
                    label="Email"
                    name="email"
                    placeholder="Email"
                    className={`w-100 h-100 ${
                      touched.email && errors.email ? "error-input" : ""
                    }`}
                    onBlur={handleBlur}
                  />
                  {touched.email && errors.email && (
                    <Error error={errors.email} />
                  )}
                </InputLayout>
                <InputLayout>
                  <label htmlFor="">Primary Contact Number</label>
                  <div className="row g-3 w-100">
                    <div className="col-4">
                      <SelectDropdown
                        data={countryCodeData}
                        placeholder="Code"
                        onChange={(option) => {
                          setFieldValue("countryCode", option);
                        }}
                        isClearable={true}
                        selectedValue={data?.phoneCountryCode}
                        onClear={(e) => {
                          setFieldValue("countryCode", " ");
                          // handleBlur(e);
                        }}
                      />
                    </div>
                    <div className="col-8 pe-0" style={{ flex: "auto" }}>
                      <Field
                        type="text"
                        name="contactNumber"
                        placeholder="Contact"
                        className={`w-100 h-100 ${
                          touched.contactNumber && errors.contactNumber
                            ? "error-input"
                            : ""
                        }`}
                        onChange={(e) => {
                          const numericInput = e.target.value.replace(
                            /[^0-9]/g,
                            "",
                          );
                          setFieldValue("contactNumber", numericInput);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                          // saveFormData(dirty, values, isValid);
                        }}
                      />
                    </div>
                    {touched.contactNumber && errors.contactNumber && (
                      <Error error={errors.contactNumber} />
                    )}
                  </div>
                </InputLayout>
                <InputLayout>
                  <label htmlFor="">Alternate Contact Number</label>
                  <div className="row g-3 w-100">
                    <div className="col-4">
                      <SelectDropdown
                        data={countryCodeData}
                        placeholder="Code"
                        onChange={(option) => {
                          setFieldValue("secCountryCode", option);
                        }}
                        isClearable={true}
                        selectedValue={data?.alternatePhoneCountryCode}
                        onClear={(e) => {
                          setFieldValue("secCountryCode", " ");
                          // handleBlur(e);
                        }}
                      />
                    </div>
                    <div className="col-8 pe-0" style={{ flex: "auto" }}>
                      <Field
                        type="text"
                        name="secContactNumber"
                        placeholder="Contact"
                        className={`w-100 h-100 ${
                          touched.secContactNumber && errors.secContactNumber
                            ? "error-input"
                            : ""
                        }`}
                        onChange={(e) => {
                          const numericInput = e.target.value.replace(
                            /[^0-9]/g,
                            "",
                          );
                          setFieldValue("secContactNumber", numericInput);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                          // saveFormData(dirty, values, isValid);
                        }}
                      />
                    </div>
                    {touched.secContactNumber && errors.secContactNumber && (
                      <Error error={errors.secContactNumber} />
                    )}
                  </div>
                </InputLayout>
              </div>
              <div className="row mt-3">
                <InputLayout>
                  <label htmlFor="">
                    Fiscal Year Starting Month{" "}
                    <span style={{ color: "#be0f00", fontWeight: "bold" }}>
                      *
                    </span>
                  </label>

                  <SelectDropdown
                    data={[
                      { value: "01", label: "January" },
                      { value: "02", label: "February" },
                      { value: "03", label: "March" },
                      { value: "04", label: "April" },
                      { value: "05", label: "May" },
                      { value: "06", label: "June" },
                      { value: "07", label: "July" },
                      { value: "08", label: "August" },
                      { value: "09", label: "September" },
                      { value: "10", label: "October" },
                      { value: "11", label: "November" },
                      { value: "12", label: "December" },
                    ]}
                    placeholder="Select Month"
                    onChange={(option) => {
                      setFieldValue("month", option?.value);
                    }}
                    isClearable={true}
                    format={"all"}
                    selectedValue={values.month}
                    onClear={() => {
                      setFieldValue("month", "");
                    }}
                    handleBlur={(e) => {
                      setFieldTouched("month", true);
                      handleBlur(e);
                    }}
                  />
                  {touched.month && errors.month && (
                    <Error error={errors.month} />
                  )}
                </InputLayout>
                <InputLayout>
                  <label htmlFor="">
                    Currency{" "}
                    <span style={{ color: "#be0f00", fontWeight: "bold" }}>
                      *
                    </span>
                  </label>

                  {/* <SelectDropdown
                    selectedValue={currency}
                    onChange={handleCurrencyChange}
                    data={currencies}
                    placeholder="Select Currency"
                  /> */}

                  <SelectDropdown
                    data={currencies}
                    placeholder="Select Currency"
                    onChange={(option) => {
                      setFieldValue("currency", option?.value);
                    }}
                    isClearable={true}
                    format={"all"}
                    selectedValue={values.currency}
                    onClear={() => {
                      setFieldValue("currency", "");
                    }}
                    handleBlur={(e) => {
                      setFieldTouched("currency", true);
                      handleBlur(e);
                    }}
                  />
                  {touched.currency && errors.currency && (
                    <Error error={errors.currency} />
                  )}
                </InputLayout>
                <InputLayout></InputLayout>
              </div>

              <div className="row mt-3">
                <div className="mt-4">
                  <h4>Director Details</h4>
                  <FieldArray name="directors">
                    {({ push, remove }) => (
                      <>
                        {values.directors.map((_, index) => (
                          <div key={index} className="row mt-3">
                            <InputLayout>
                              <label htmlFor={`directors.${index}.DIN_PAN`}>
                                DIN/PAN
                              </label>
                              <Field
                                type="text"
                                name={`directors.${index}.DIN_PAN`}
                                placeholder="DIN/PAN"
                                className={`w-100 h-100 ${
                                  touched.directors?.[index]?.DIN_PAN &&
                                  errors.directors?.[index]?.DIN_PAN
                                    ? "error-input"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name={`directors.${index}.DIN_PAN`}
                                component={Error}
                              />
                            </InputLayout>
                            <InputLayout>
                              <label
                                htmlFor={`directors.${index}.directorName`}
                              >
                                Director Name
                              </label>
                              <Field
                                type="text"
                                name={`directors.${index}.directorName`}
                                placeholder="Director Name"
                                className={`w-100 h-100 ${
                                  touched.directors?.[index]?.directorName &&
                                  errors.directors?.[index]?.directorName
                                    ? "error-input"
                                    : ""
                                }`}
                              />
                              <ErrorMessage
                                name={`directors.${index}.directorName`}
                                component={Error}
                              />
                            </InputLayout>
                            <InputLayout>
                              <label
                                htmlFor={`directors.${index}.dateOfAppointment`}
                              >
                                Date of Appointment
                              </label>
                              <DateSelector
                                placeholder="Select Date of Appointment"
                                format="DD/MM/YYYY"
                                onChange={(date) =>
                                  setFieldValue(
                                    `directors.${index}.dateOfAppointment`,
                                    date,
                                  )
                                }
                                value={
                                  values.directors[index].dateOfAppointment
                                }
                                selectedDate={
                                  values.directors[index].dateOfAppointment
                                }
                                minDateData={values.dateofIncorporation}
                              />
                              <ErrorMessage
                                name={`directors.${index}.dateOfAppointment`}
                                component={Error}
                              />
                            </InputLayout>
                            <div className="col-lg-1 col-md-1 d-flex align-items-center">
                              <button
                                className="btn-add"
                                type="button"
                                onClick={() =>
                                  push({
                                    DIN_PAN: "",
                                    directorName: "",
                                    dateOfAppointment: "",
                                  })
                                }
                              >
                                <Add />
                              </button>
                              {values.directors.length > 1 && (
                                <button
                                  className="btn-add"
                                  type="button"
                                  onClick={() => remove(index)}
                                >
                                  <Delete color="white" />
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </div>

                <div className="mt-4">
                  <h4>Shareholder Details</h4>
                  <FieldArray name="shareholders">
                    {({ push, remove }) => (
                      <>
                        {values.shareholders.map((_, index) => (
                          <div key={index} className=" mt-3">
                            <div className="mt-3 row">
                              <InputLayout>
                                <label
                                  htmlFor={`shareholders.${index}.shareholderName`}
                                >
                                  Shareholder Name
                                </label>
                                <Field
                                  type="text"
                                  name={`shareholders.${index}.shareholderName`}
                                  placeholder="Shareholder Name"
                                  className={`w-100 h-100 ${
                                    touched.shareholders?.[index]
                                      ?.shareholderName &&
                                    errors.shareholders?.[index]
                                      ?.shareholderName
                                      ? "error-input"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name={`shareholders.${index}.shareholderName`}
                                  component={Error}
                                />
                              </InputLayout>
                              <InputLayout>
                                <label htmlFor={`shareholders.${index}.folio`}>
                                  Folio
                                </label>
                                <Field
                                  type="text"
                                  name={`shareholders.${index}.folio`}
                                  placeholder="Folio"
                                  className={`w-100 h-100 ${
                                    touched.shareholders?.[index]?.folio &&
                                    errors.shareholders?.[index]?.folio
                                      ? "error-input"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name={`shareholders.${index}.folio`}
                                  component={Error}
                                />
                              </InputLayout>
                              <InputLayout>
                                <label htmlFor={`shareholders.${index}.dp_id`}>
                                  DP ID
                                </label>
                                <Field
                                  type="text"
                                  name={`shareholders.${index}.dp_id`}
                                  placeholder="DP ID"
                                  className={`w-100 h-100 ${
                                    touched.shareholders?.[index]?.dp_id &&
                                    errors.shareholders?.[index]?.dp_id
                                      ? "error-input"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name={`shareholders.${index}.dp_id`}
                                  component={Error}
                                />
                              </InputLayout>
                            </div>
                            <div className="mt-3 row">
                              <InputLayout>
                                <label
                                  htmlFor={`shareholders.${index}.sharesHeldNumber`}
                                >
                                  Number of Shares
                                </label>
                                <Field
                                  type="number"
                                  name={`shareholders.${index}.sharesHeldNumber`}
                                  placeholder="Number of Shares"
                                  className={`w-100 h-100 ${
                                    touched.shareholders?.[index]
                                      ?.sharesHeldNumber &&
                                    errors.shareholders?.[index]
                                      ?.sharesHeldNumber
                                      ? "error-input"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name={`shareholders.${index}.sharesHeldNumber`}
                                  component={Error}
                                />
                              </InputLayout>
                              <InputLayout>
                                <label
                                  htmlFor={`shareholders.${index}.classOfShares`}
                                >
                                  Class of Shares
                                </label>
                                <Field
                                  type="text"
                                  name={`shareholders.${index}.classOfShares`}
                                  placeholder="Class of Shares"
                                  className={`w-100 h-100 ${
                                    touched.shareholders?.[index]
                                      ?.classOfShares &&
                                    errors.shareholders?.[index]?.classOfShares
                                      ? "error-input"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name={`shareholders.${index}.classOfShares`}
                                  component={Error}
                                />
                              </InputLayout>
                              <InputLayout>
                                <label
                                  htmlFor={`shareholders.${index}.percentageOfShareholding`}
                                >
                                  Percentage of Shareholding
                                </label>
                                <Field
                                  type="number"
                                  name={`shareholders.${index}.percentageOfShareholding`}
                                  placeholder="Percentage of Shareholding"
                                  className={`w-100 h-100 ${
                                    touched.shareholders?.[index]
                                      ?.percentageOfShareholding &&
                                    errors.shareholders?.[index]
                                      ?.percentageOfShareholding
                                      ? "error-input"
                                      : ""
                                  }`}
                                />
                                <ErrorMessage
                                  name={`shareholders.${index}.percentageOfShareholding`}
                                  component={Error}
                                />
                              </InputLayout>
                              <div className="col-lg-1 col-md-1 d-flex align-items-center">
                                <button
                                  className="btn-add"
                                  type="button"
                                  onClick={() =>
                                    push({
                                      shareholderName: "",
                                      folio: "",
                                      dp_id: "",
                                      sharesHeldNumber: "",
                                      classOfShares: "",
                                      percentageOfShareholding: "",
                                    })
                                  }
                                >
                                  <Add />
                                </button>
                                {values.shareholders.length > 1 && (
                                  <button
                                    className="btn-add"
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    <Delete
                                      color="white"
                                      width={24}
                                      height={24}
                                    />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </div>
              </div>

              <div className="mt-3">
                <h4>Registered Office</h4>

                <div className="mt-3 row">
                  <InputLayout>
                    <label htmlFor="registeredOffice.country">Country</label>
                    <SelectDropdown
                      data={countryNameData}
                      placeholder="Country"
                      id="country"
                      label="Country"
                      // onChange={(option) => {
                      //   // setFieldValue("country", option);
                      //   setFieldValue("registeredOffice.country", option);
                      //   handleSelectedCountry(option, setFieldValue);
                      //   if (option !== "India") {
                      //     setFieldValue("registeredOffice.state", "");
                      //     setFieldValue("registeredOffice.city", "");
                      //     setFieldValue("registeredOffice.pincode", "");
                      //   }
                      // }}
                      onChange={(option) => {
                        handleRegisteredOfficeChange(
                          "country",
                          option,
                          setFieldValue,
                          values,
                        );
                        if (option !== "India") {
                          handleRegisteredOfficeChange(
                            "state",
                            "",
                            setFieldValue,
                            values,
                          );
                          handleRegisteredOfficeChange(
                            "city",
                            "",
                            setFieldValue,
                            values,
                          );
                          handleRegisteredOfficeChange(
                            "pincode",
                            "",
                            setFieldValue,
                            values,
                          );
                        }
                      }}
                      isClearable={false}
                      selectedValue={values?.registeredOffice?.country}
                      onClear={(e) => {
                        handleSelectedCountry("");
                      }}
                      custom={true}
                      menuPlacement="top"
                    />
                    <ErrorMessage
                      name="registeredOffice.country"
                      component={Error}
                    />
                  </InputLayout>
                  {values.registeredOffice.country !== "India" && (
                    <>
                      <InputLayout></InputLayout>
                      <InputLayout></InputLayout>
                      <InputLayout></InputLayout>
                    </>
                  )}

                  {values.registeredOffice.country === "India" && (
                    <>
                      <InputLayout>
                        <label htmlFor="registeredOffice.pincode">
                          Pincode
                        </label>
                        <Field
                          type="text"
                          name="registeredOffice.pincode"
                          placeholder="Pincode"
                          onChange={(e) =>
                            handlePincodeChange(
                              setFieldValue,
                              e,
                              "registeredOffice",
                            )
                          }
                          className={`w-100 h-100 ${
                            touched.registeredOffice?.pincode &&
                            errors.registeredOffice?.pincode
                              ? "error-input"
                              : ""
                          }`}
                          maxLength={6}
                          onKeyDown={(e) => {
                            const allowedKeys = [
                              "Backspace",
                              "Tab",
                              "ArrowLeft",
                              "ArrowRight",
                              "Delete",
                              "Enter",
                            ];
                            if (
                              e.ctrlKey ||
                              e.metaKey ||
                              (e.key === "v" && e.shiftKey)
                            ) {
                              return; // Allow copy and paste
                            }
                            if (
                              /\d/.test(e.key) ||
                              allowedKeys.includes(e.key)
                            ) {
                              return; // Allow the key
                            }
                            e.preventDefault(); // Block other keys
                          }}
                        />
                        <ErrorMessage
                          name="registeredOffice.pincode"
                          component={Error}
                        />
                      </InputLayout>
                      <InputLayout>
                        <label htmlFor="registeredOffice.state">State</label>
                        <SelectDropdown
                          data={stateNameslist}
                          placeholder="State"
                          id="State"
                          label="State"
                          // onChange={(option) => {
                          //   handleSelectedState(option);
                          //   setFieldValue("pincode", null); // Clear pincode
                          //   setFieldValue("address", ""); // Clear address
                          //   setFieldValue("city", ""); // Clear city
                          // }}
                          onChange={(option) =>
                            handleRegisteredOfficeChange(
                              "state",
                              option,
                              setFieldValue,
                              values,
                            )
                          }
                          isClearable={false}
                          selectedValue={values.registeredOffice.state}
                          // onClear={(e) => {
                          //   handleSelectedState("");
                          // }}
                          onClear={(e) => {
                            setFieldValue("registeredOffice.state", "");
                            setSelectedState("");
                          }}
                          custom={true}
                          menuPlacement="top"
                        />
                        <ErrorMessage
                          name="registeredOffice.state"
                          component={Error}
                        />
                      </InputLayout>
                      <InputLayout>
                        <label htmlFor="registeredOffice.city">City</label>
                        <Field
                          type="text"
                          name="registeredOffice.city"
                          placeholder="City"
                          className={`w-100 h-100 ${
                            touched.registeredOffice?.city &&
                            errors.registeredOffice?.city
                              ? "error-input"
                              : ""
                          }`}
                          onChange={(e) =>
                            handleRegisteredOfficeChange(
                              "city",
                              e.target.value,
                              setFieldValue,
                              values,
                            )
                          }
                        />
                        <ErrorMessage
                          name="registeredOffice.city"
                          component={Error}
                        />
                      </InputLayout>
                    </>
                  )}
                </div>
                <InputLayout>
                  <label htmlFor="registeredOffice.address">Address</label>
                  <Field
                    type="text"
                    name="registeredOffice.address"
                    placeholder="Address"
                    className={`w-100 h-100 ${
                      touched.registeredOffice?.address &&
                      errors.registeredOffice?.address
                        ? "error-input"
                        : ""
                    }`}
                    onChange={(e) =>
                      handleRegisteredOfficeChange(
                        "address",
                        e.target.value,
                        setFieldValue,
                        values,
                      )
                    }
                  />
                  <ErrorMessage
                    name="registeredOffice.address"
                    component={Error}
                  />
                </InputLayout>
              </div>

              {
                <div className="mt-4">
                  <h4>Business Office</h4>
                  <div className="mt-4 d-flex justify-content align-items-center">
                    <span style={{ fontWeight: "bold", paddingRight: "20px" }}>
                      Same as Registered Office Address
                    </span>
                    <div
                      className={`toggle-button ${
                        isSameAddress ? "disabled" : "enabled"
                      }`}
                      // className={`toggle-button ${
                      //   data?.isSameAddress ?? isSameAddress
                      //     ? "disabled"
                      //     : "enabled"
                      // }`}
                      setFieldValue={setFieldValue}
                      values={values}
                      onClick={() => handleToggle(setFieldValue, values)}
                    >
                      <span className="button-text">Toggle</span>
                    </div>
                  </div>
                  {/* {!isSameAddress && ( */}
                  <div className="mt-3 row">
                    <InputLayout>
                      <label htmlFor="businessOffice.country">Country</label>
                      <SelectDropdown
                        data={countryNameData}
                        placeholder="Country"
                        id="country"
                        label="Country"
                        // onChange={(option) => {
                        //   // setFieldValue("country", option);
                        //   setFieldValue("registeredOffice.country", option);
                        //   handleSelectedCountry(option, setFieldValue);
                        //   if (option !== "India") {
                        //     setFieldValue("registeredOffice.state", "");
                        //     setFieldValue("registeredOffice.city", "");
                        //     setFieldValue("registeredOffice.pincode", "");
                        //   }
                        // }}
                        onChange={(option) => {
                          setFieldValue("businessOffice.country", option);
                          setSelectedCountry(option);
                          if (option !== "India") {
                            setFieldValue("businessOffice.state", "");
                            setFieldValue("businessOffice.city", "");
                            setFieldValue("businessOffice.pincode", "");
                            setSelectedState("");
                          }
                        }}
                        isClearable={false}
                        selectedValue={values.businessOffice.country}
                        onClear={(e) => {
                          handleSelectedCountry("");
                        }}
                        custom={true}
                        menuPlacement="top"
                        isDisabled={isSameAddress ? true : false}
                      />
                      <ErrorMessage
                        name="businessOffice.country"
                        component={Error}
                      />
                    </InputLayout>
                    {values.businessOffice.country !== "India" && (
                      <>
                        <InputLayout></InputLayout>
                        <InputLayout></InputLayout>
                        <InputLayout></InputLayout>
                      </>
                    )}
                    {values.businessOffice.country === "India" && (
                      <>
                        <InputLayout>
                          <label htmlFor="businessOffice.pincode">
                            Pincode
                          </label>
                          <Field
                            type="text"
                            name="businessOffice.pincode"
                            placeholder="Pincode"
                            onChange={(e) =>
                              handlePincodeChange(
                                setFieldValue,
                                e,
                                "businessOffice",
                              )
                            }
                            className={`w-100 h-100 ${
                              touched.businessOffice?.pincode &&
                              errors.businessOffice?.pincode
                                ? "error-input"
                                : ""
                            }`}
                            disabled={isSameAddress ? true : false}
                            maxLength={6}
                            onKeyDown={(e) => {
                              const allowedKeys = [
                                "Backspace",
                                "Tab",
                                "ArrowLeft",
                                "ArrowRight",
                                "Delete",
                                "Enter",
                              ];
                              if (
                                e.ctrlKey ||
                                e.metaKey ||
                                (e.key === "v" && e.shiftKey)
                              ) {
                                return; // Allow copy and paste
                              }
                              if (
                                /\d/.test(e.key) ||
                                allowedKeys.includes(e.key)
                              ) {
                                return; // Allow the key
                              }
                              e.preventDefault(); // Block other keys
                            }}
                          />
                          <ErrorMessage
                            name="businessOffice.pincode"
                            component={Error}
                          />
                        </InputLayout>
                        <InputLayout>
                          <label htmlFor="businessOffice.state">State</label>
                          <SelectDropdown
                            data={stateNameslist}
                            placeholder="State"
                            id="State"
                            label="State"
                            // onChange={(option) => {
                            //   handleSelectedState(option);
                            //   setFieldValue("pincode", null); // Clear pincode
                            //   setFieldValue("address", ""); // Clear address
                            //   setFieldValue("city", ""); // Clear city
                            // }}
                            onChange={(option) => {
                              setFieldValue("businessOffice.state", option);
                              setSelectedState(option);
                            }}
                            isClearable={false}
                            selectedValue={values.businessOffice.state}
                            // onClear={(e) => {
                            //   handleSelectedState("");
                            // }}
                            onClear={(e) => {
                              setFieldValue("businessOffice.state", "");
                              setSelectedState("");
                            }}
                            custom={true}
                            menuPlacement="top"
                            isDisabled={isSameAddress ? true : false}
                          />
                          <ErrorMessage
                            name="businessOffice.state"
                            component={Error}
                          />
                        </InputLayout>
                        <InputLayout>
                          <label htmlFor="businessOffice.city">City</label>
                          <Field
                            type="text"
                            name="businessOffice.city"
                            placeholder="City"
                            className={`w-100 h-100 ${
                              touched.businessOffice?.city &&
                              errors.businessOffice?.city
                                ? "error-input"
                                : ""
                            }`}
                            disabled={isSameAddress ? true : false}
                          />
                          <ErrorMessage
                            name="businessOffice.city"
                            component={Error}
                          />
                        </InputLayout>
                      </>
                    )}
                  </div>
                  <InputLayout>
                    <label htmlFor="businessOffice.address">Address</label>
                    <Field
                      type="text"
                      name="businessOffice.address"
                      placeholder="Address"
                      className={`w-100 h-100 ${
                        touched.businessOffice?.address &&
                        errors.businessOffice?.address
                          ? "error-input"
                          : ""
                      }`}
                      disabled={isSameAddress ? true : false}
                    />
                    <ErrorMessage
                      name="businessOffice.address"
                      component={Error}
                    />
                  </InputLayout>
                </div>
              }

              <div className="d-flex gap-3 mt-4 ">
                {type === "add" ? (
                  <CustomButton
                    iconRequired={false}
                    type="btn-primary"
                    buttonType="submit"
                    // handleClick={() =>!isValid || !dirty || isSaving ? {setFieldTouched("currency",true)} : handleSubmit(values)}
                    handleClick={() => {
                      if (!isValid || !dirty || isSaving) {
                        setFieldTouched("currency", true);
                        setFieldTouched("month", true);
                        setFieldTouched("companyname", true);
                        window.scrollTo(0, 0);
                      } else {
                        handleSubmit(values);
                      }
                    }}

                    // disabled={!isValid || !dirty || isSaving}
                  >
                    Add Company
                    {isSaving && (
                      <span
                        className="spinner-border spinner-border-sm ms-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </CustomButton>
                ) : (
                  <CustomButton
                    iconRequired={false}
                    type="btn-primary"
                    buttonType="submit"
                    handleClick={() => handleEdit(values)}
                    disabled={!isValid || isSaving}
                  >
                    Edit Company
                    {isSaving && (
                      <span
                        className="spinner-border spinner-border-sm ms-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </CustomButton>
                )}
                <CustomButton
                  text="Cancel"
                  iconRequired={false}
                  handleClick={() => {
                    navigate(-1);
                  }}
                />
              </div>
            </>
          )}
        </Formik>
      </PageCard>
    </>
  );
};
export default AddEmployee;
