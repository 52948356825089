// import React, { useState, useEffect } from "react";
// import Error from "./Error";
// import { getFileName } from "../../../helpers/fileName";
// import "./styles.scss";

// const CustomDropzone = ({
//   onFileSelect,
//   adType,
//   id,
//   type,
//   selectedFile,
//   description,
//   mainDescription,
//   maxFileSizeMB = 20,
// }) => {
//   const [isDragOver, setIsDragOver] = useState(false);
//   const [file, setFile] = useState(null);
//   const [error, setError] = useState(null);
//   const [fileName, setFileName] = useState(null);
//   const [filePreviewUrl, setFilePreviewUrl] = useState(null);
//   const [showImagePreview, setShowImagePreview] = useState(false);

//   // Define allowed file types
//   const allowedTypes = [
//     "image/png",
//     "image/jpeg",
//     "image/gif",
//     "application/pdf",
//   ];

//   useEffect(() => {
//     if (selectedFile) {
//       setFileName(getFileName(selectedFile));
//       setFile(selectedFile);
//       generateFilePreview(selectedFile);
//     }
//   }, [selectedFile]);

//   let allowedFileTypes = allowedTypes;

//   if (adType === "Document") {
//     allowedFileTypes = [
//       "application/pdf",
//       "application/msword",
//       "application/vnd.ms-excel",
//       "text/csv",
//     ];
//   } else if (adType === "Excel") {
//     allowedFileTypes = [
//       "application/vnd.ms-excel",
//       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//     ];
//   } else if (adType === "Audio") {
//     allowedFileTypes = ["audio/mpeg", "audio/wav", "audio/mp3"];
//   } else if (adType === "Pdf") {
//     allowedFileTypes = ["application/pdf"];
//   } else if (adType === "Image") {
//     allowedFileTypes = ["image/png", "image/jpeg", "image/svg+xml"];
//   } else if (adType === "Others") {
//     allowedFileTypes = [
//       "application/msword",
//       "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//       "text/csv",
//       "application/vnd.ms-powerpoint",
//       "application/vnd.openxmlformats-officedocument.presentationml.presentation",
//     ];
//   }

//   const handleDragEnter = (e) => {
//     e.preventDefault();
//     setIsDragOver(true);
//   };

//   const handleDragLeave = () => {
//     setIsDragOver(false);
//   };

//   const handleDragOver = (e) => {
//     e.preventDefault();
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     setIsDragOver(false);

//     const droppedFile = e.dataTransfer.files[0];
//     validateAndSetFile(droppedFile);
//   };

//   const handleFileChange = (e) => {
//     const selectedFile = e.target.files[0];
//     validateAndSetFile(selectedFile);
//   };

//   const validateAndSetFile = (file) => {
//     if (isFileTypeAllowed(file?.type) && isFileSizeAllowed(file?.size)) {
//       setFile(file);
//       setFileName(file?.name);
//       setError(null);
//       generateFilePreview(file);
//       onFileSelect(file);
//     } else {
//       setError(generateErrorMessage(file?.type, file?.size));
//       setFile(null);
//       setFileName(null);
//       setFilePreviewUrl(null);
//       setShowImagePreview(false);
//     }
//   };

//   const isFileTypeAllowed = (fileType) => {
//     return allowedFileTypes ? allowedFileTypes.includes(fileType) : true;
//   };

//   const isFileSizeAllowed = (fileSize) => {
//     const maxSizeBytes = maxFileSizeMB * 1024 * 1024;
//     return fileSize <= maxSizeBytes;
//   };

//   const generateErrorMessage = (fileType, fileSize) => {
//     let errorMessage = "File type or size not allowed.";

//     if (!isFileTypeAllowed(fileType)) {
//       errorMessage = "File type not supported. Please upload a valid file.";
//     } else if (!isFileSizeAllowed(fileSize)) {
//       errorMessage = `File size must be less than ${maxFileSizeMB} MB.`;
//     }

//     return <span style={{ color: "#be0f00" }}>{errorMessage}</span>;
//   };

//   const generateFilePreview = (file) => {
//     const reader = new FileReader();

//     reader.onloadend = () => {
//       setFilePreviewUrl(reader.result);
//     };

//     if (file) {
//       if (file.type.startsWith("image/")) {
//         reader.readAsDataURL(file);
//       } else if (file.type === "application/pdf") {
//         setFilePreviewUrl(URL.createObjectURL(file));
//       } else {
//         setFilePreviewUrl(null);
//       }
//     }
//   };

//   const handleViewClick = () => {
//     if (file?.type.startsWith("image/")) {
//       setShowImagePreview(true);
//     } else if (file?.type === "application/pdf") {
//       window.open(filePreviewUrl, "_blank");
//     }
//   };

//   const handleClosePreview = () => {
//     setShowImagePreview(false);
//   };

//   return (
//     <>
//       <div className="d-flex gap-3">
//         <div
//           className={`dropzone ${isDragOver ? "drag-over" : ""}`}
//           onDragEnter={handleDragEnter}
//           onDragLeave={handleDragLeave}
//           onDragOver={handleDragOver}
//           onDrop={handleDrop}
//           onClick={() =>
//             document.querySelector(`#dropzone-${id}-file-input`).click()
//           }
//           key={id}
//         >
//           <input
//             type="file"
//             id={`dropzone-${id}-file-input`}
//             onChange={handleFileChange}
//             style={{ display: "none" }}
//           />
//           <i className="fi fi-rr-upload"></i>
//           <p>
//             {mainDescription
//               ? mainDescription
//               : "Drag and drop a file here, or select a file"}
//           </p>
//           {description && (
//             <p
//               style={{
//                 fontSize: "12px",
//                 fontWeight: "bold",
//                 marginTop: "-10px",
//               }}
//               className="mb-0"
//             >
//               {description}
//             </p>
//           )}
//           <p className="mb-0" style={{ fontSize: "12px" }}>
//             Upto {maxFileSizeMB} Mb is allowed
//           </p>

//           {error && <Error error={error} />}

//           {file && !error && (
//             <div className="file-info">
//               <span>
//                 <b>Selected File :</b> {fileName}
//               </span>
//             </div>
//           )}
//         </div>
//         {file && (
//           <button
//             onClick={handleViewClick}
//             style={{
//               background: "none",
//               border: "none",
//               textDecoration: "underline",
//               cursor: "pointer",
//               padding: 0,
//               fontSize: "inherit",
//             }}
//           >
//             <b>View</b>
//           </button>
//         )}
//       </div>

//       {showImagePreview && file?.type.startsWith("image/") && (
//         <div className="image-preview-overlay">
//           <div className="image-preview-content">
//             <img
//               src={filePreviewUrl}
//               alt="Preview"
//               style={{
//                 maxWidth: "100%",
//                 maxHeight: "90vh",
//               }}
//             />
//             <button className="close-button" onClick={handleClosePreview}>
//               &times;
//             </button>
//             <p className="file-name">{fileName}</p>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default CustomDropzone;

import React, { useState, useEffect } from "react";
import Error from "./Error";
import { getFileName } from "../../../helpers/fileName";
import "./styles.scss";

const CustomDropzone = ({
  onFileSelect,
  adType,
  id,
  type,
  selectedFile,
  description,
  mainDescription,
  maxFileSizeMB = 20,
}) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState(null);
  const [showImagePreview, setShowImagePreview] = useState(false);

  const allowedTypes = [
    "image/png",
    "image/jpeg",
    "image/gif",
    "application/pdf",
  ];

  useEffect(() => {
    if (selectedFile) {
      setFileName(getFileName(selectedFile));
      setFile(selectedFile);
      generateFilePreview(selectedFile);
    }
  }, [selectedFile]);

  let allowedFileTypes = allowedTypes;
  if (adType === "Document") {
    allowedFileTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.ms-excel",
      "text/csv",
    ];
  } else if (adType === "Excel") {
    allowedFileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
  } else if (adType === "Audio") {
    allowedFileTypes = ["audio/mpeg", "audio/wav", "audio/mp3"];
  } else if (adType === "Pdf") {
    allowedFileTypes = ["application/pdf"];
  } else if (adType === "Image") {
    allowedFileTypes = ["image/png", "image/jpeg", "image/svg+xml"];
  } else if (adType === "Others") {
    allowedFileTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/csv",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const droppedFile = e.dataTransfer.files[0];
    validateAndSetFile(droppedFile);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    validateAndSetFile(selectedFile);
  };

  const validateAndSetFile = (file) => {
    if (isFileTypeAllowed(file?.type) && isFileSizeAllowed(file?.size)) {
      setFile(file);
      setFileName(file?.name);
      setError(null);
      generateFilePreview(file);
      onFileSelect(file);
    } else {
      setError(generateErrorMessage(file?.type, file?.size));
      setFile(null);
      setFileName(null);
      setFilePreviewUrl(null);
      setShowImagePreview(false);
    }
  };

  const isFileTypeAllowed = (fileType) => allowedFileTypes.includes(fileType);

  const isFileSizeAllowed = (fileSize) => {
    const maxSizeBytes = maxFileSizeMB * 1024 * 1024;
    return fileSize <= maxSizeBytes;
  };

  const generateErrorMessage = (fileType, fileSize) => {
    if (!isFileTypeAllowed(fileType)) {
      return (
        <span style={{ color: "#be0f00" }}>
          File type not supported. Please upload a valid file.
        </span>
      );
    } else if (!isFileSizeAllowed(fileSize)) {
      return (
        <span style={{ color: "#be0f00" }}>
          File size must be less than {maxFileSizeMB} MB.
        </span>
      );
    }
    return (
      <span style={{ color: "#be0f00" }}>File type or size not allowed.</span>
    );
  };

  const generateFilePreview = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => setFilePreviewUrl(reader.result);
    if (file) {
      if (file.type.startsWith("image/")) {
        reader.readAsDataURL(file);
      } else if (file.type === "application/pdf") {
        setFilePreviewUrl(URL.createObjectURL(file));
      } else {
        setFilePreviewUrl(null);
      }
    }
  };

  const handleViewClick = () => {
    if (file?.type.startsWith("image/")) {
      setShowImagePreview(true);
    } else if (file?.type === "application/pdf") {
      window.open(filePreviewUrl, "_blank");
    }
  };

  const handleClosePreview = () => setShowImagePreview(false);

  return (
    <>
      <div className="d-flex gap-3">
        <div
          className={`dropzone ${isDragOver ? "drag-over" : ""}`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={() =>
            document.querySelector(`#dropzone-${id}-file-input`).click()
          }
          key={id}
        >
          <input
            type="file"
            id={`dropzone-${id}-file-input`}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          {!file ? (
            <>
              <i className="fi fi-rr-upload"></i>
              <p>
                {mainDescription ||
                  "Drag and drop a file here, or select a file"}
              </p>
              {description && (
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginTop: "-10px",
                  }}
                  className="mb-0"
                >
                  {description}
                </p>
              )}
              <p className="mb-0" style={{ fontSize: "12px" }}>
                Upto {maxFileSizeMB} Mb is allowed
              </p>
            </>
          ) : (
            <div className="file-info" style={{ marginTop: "-10px" }}>
              <span>
                <b>Selected File :</b> {fileName}
              </span>
            </div>
          )}
          {error && <Error error={error} />}
        </div>
        {file && (
          <button
            onClick={handleViewClick}
            style={{
              background: "none",
              border: "none",
              textDecoration: "underline",
              cursor: "pointer",
              padding: 0,
              fontSize: "inherit",
            }}
          >
            <b>View</b>
          </button>
        )}
      </div>

      {showImagePreview && file?.type.startsWith("image/") && (
        <div className="image-preview-overlay">
          <div className="image-preview-content">
            <img
              src={filePreviewUrl}
              alt="Preview"
              style={{ maxWidth: "100%", maxHeight: "90vh" }}
            />
            <button className="close-button" onClick={handleClosePreview}>
              &times;
            </button>
            <p className="file-name">{fileName}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomDropzone;
