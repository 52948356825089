export const customDropdownStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "51px !important",
    outline: state.isFocused ? "none" : "none",
    backgroundColor: state.isFocused ? "#ffffff" : "#F4F4F4", // Change to your focused background color
    border: state.isFocused
      ? "1px solid #145b64 !important"
      : "1px solid #F4F4F4 !important", // Change to your focused border color and width
    borderRadius: "10px",
    boxShadow: "none",
    paddingLeft: "12px",
    fontSize: "14px",
    fontWeight: 500,
    // ":hover": {
    //   border: "1px solid #3D9BE9", // Change to your hover border color and width
    // },
  }),
  valueContainer: (provided) => ({
    ...provided,
    minheight: "46px",
    padding: 0,
  }),
  inputContainer: (provided) => ({
    ...provided,
    minHeight: "46px",
  }),
  input: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
    fontSize: "12px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? "#F8F8F8"
      : state.isSelected
      ? "#F4F4F4"
      : provided.backgroundColor,
    color: provided,
    ":hover": {
      backgroundColor: state.isFocused
        ? "#F8F8F8"
        : state.isSelected
        ? "#F4F4F4"
        : "#F4F4F4",
    },
  }),
  menu: (provided) => ({
    ...provided,
    // maxHeight: "200px", // Adjust this value based on your requirement
    overflowY: "auto",
    zIndex: 10,
    minWidth: "200px",
    border: "none",
    borderRadius: "16px",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "none",
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    color: "#1A1D1F !important",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "hsl(0,0%,0%)",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "hsl(0,0%,0%)",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#fff", // Set your desired color here
    borderRadius: "6px",
    border: "1px solid #efefef",
    padding: "5px",
    paddingTop: "2px",
    paddingBottom: "2px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: "15px",
    fontWeight: 500,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: "#fff", // Set the background color for the remove button
    cursor: "pointer", // Add a pointer cursor to indicate it's clickable
    color: "#6F767E",
    borderRadius: "6px",
    ":hover": {
      backgroundColor: "#fff", // Set the background color on hover
    },
  }),
};

export const errorStyles = {
  control: (provided, state) => ({
    ...provided,
    minheight: "46px",
    outline: state.isFocused ? "none" : "none",
    backgroundColor: state.isFocused ? "#ffffff" : "#F4F4F4", // Change to your focused background color
    border: "1px solid #be0f00 !important", // Change to your focused border color and width
    borderRadius: "10px",
    boxShadow: "none",
    paddingLeft: "12px",
    fontSize: "14px",
    fontWeight: 500,
  }),
};

export const dashBoardStyles = {
  inputContainer: (provided) => ({
    ...provided,
    width: "380px",
    maxWidth: "380px",
  }),
  valueContainer2: (provided) => ({
    ...provided,
    backgroundColor: "black",
    maxWidth: "396px",
    width: "396px",
    WebkitFlexWrap: "nowrap !important",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "white",
    minWidth: "max-content !important",
  }),
  valueContainer: (provided) => ({
    ...provided,
    WebkitFlexWrap: "nowrap !important", // Changed -webkit-flex-wrap to WebkitFlexWrap
    maxWidth: "255px",
    overflowX: "scroll !important", // Changed overflow-x to overflowX and removed semicolon from !important
  }),
  control: (provided, state) => ({
    ...provided,
    minheight: "46px",
    outline: state.isFocused ? "none" : "none",
    backgroundColor: "#F4F4F4",
    border: 0,
    borderRadius: "10px",
    boxShadow: "none",
    paddingLeft: "12px",
    minWidth: "20vw",
  }),
};
