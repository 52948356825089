import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Heading from "../../../atoms/FormHeading";
import Loader from "../../../atoms/Loader";
import Form from "./Form";

const ResetOrCreatePass = ({ type }) => {
  // const [showForm, setShowForm] = useState(null);
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  // Extract the user_id and expiry values
  const userId = params.get("userId");
  const token = params.get("token");
  const expiry = new Date(params.get("expiry"));
  const currentTime = new Date();

  // useEffect(() => {
  //   if (currentTime < expiry) {
  //     setShowForm(true);
  //   } else {
  //     setShowForm(false);
  //   }
  // }, [location]);

  return (
    <>
      {/* {showForm == null ? (
        <Loader />
      ) : showForm ? ( */}
      <>
        <Heading
          title={type === "reset" ? "Reset Password" : "Create Password"}
          description={`Enter a new password to ${type} the password of your account. We’ll ask for this password whenever you log in.`}
        />
        <Form type={type} uid={userId} token={token} expiry={expiry} />
      </>
      {/* ) : (
        <Heading
          title="Link Expired"
          description="This link has already been expired. Please generate a new link."
          type="center"
        />
      )} */}
    </>
  );
};

export default ResetOrCreatePass;
