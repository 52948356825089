import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import logo from "../../../../assets/branding/WEchartered_Logo.jpg";

import Form from "./Form";

const AuthLogin = () => {
  const navigate = useNavigate();
  // Back btn Handling ---------------------------------------------------------------------------------------------
  const onButtonEvent = (e) => {
    e.preventDefault();
    navigate(0);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", onButtonEvent);
    return () => {
      window.removeEventListener("popstate", onButtonEvent);
    };
  }, []);
  // --------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="w-100 d-flex justify-content-left">
        <img src={logo} alt="" style={{ width: "50%" }} />
      </div>
      <div className="h1-i mb-4">Login to Financial Analytics Platform</div>
      <Form />
    </>
  );
};

export default AuthLogin;
