export const formatValue = (value, unit, currency) => {
  if (typeof value !== "number") {
    return value;
  }

  const cleanUnit = unit?.trim().toLowerCase();
  const cleanCurrency = currency?.trim().toUpperCase(); // Normalize currency for consistent comparison

  // Format for Indian numbering system
  if (
    cleanUnit === "lakhs" ||
    cleanUnit === "crore" ||
    (cleanUnit === "hundreds" && cleanCurrency === "INR") ||
    (cleanUnit === "thousands" && cleanCurrency === "INR") ||
    (cleanUnit === "actual" && cleanCurrency === "INR")
  ) {
    return value.toLocaleString("en-IN", { maximumFractionDigits: 2 });
  }

  // Format for Western numbering system
  if (
    cleanUnit === "million" ||
    cleanUnit === "billion" ||
    (cleanUnit === "hundreds" && cleanCurrency !== "INR") ||
    (cleanUnit === "thousands" && cleanCurrency !== "INR") ||
    (cleanUnit === "actual" && cleanCurrency !== "INR")
  ) {
    return value.toLocaleString("en-US", { maximumFractionDigits: 2 });
  }

  // Fallback to general formatting (default to Western system)
  return value.toLocaleString("en-US", { maximumFractionDigits: 2 });
};
