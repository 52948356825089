// import { Info } from "@mui/icons-material";
// import React, { useState } from "react";

// const Information = ({ calculation }) => {
//   const [showTooltip, setShowTooltip] = useState(false);
//   console.log("calculation--------->", calculation);

//   return (
//     <span className="relative inline-block ml-2">
//       <Info
//         className="text-blue-500 cursor-pointer"
//         onMouseEnter={() => setShowTooltip(true)}
//         onMouseLeave={() => setShowTooltip(false)}
//       />
//       {showTooltip && (
//         <div className="absolute z-10 w-64 p-2 mt-2 text-sm text-white bg-black rounded-lg shadow-lg">
//           <pre className="whitespace-pre-wrap">{calculation}</pre>
//         </div>
//       )}
//     </span>
//   );
// };

// export default Information;

import { Info } from "@mui/icons-material";
import React, { useState, useRef, useEffect } from "react";

const Information = ({ calculation }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const infoRef = useRef(null);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShowTooltip(false);
    }, 300);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (showTooltip && infoRef.current && tooltipRef.current) {
      const rect = infoRef.current.getBoundingClientRect();
      tooltipRef.current.style.top = `${rect.top + window.scrollY - 10}px`; // Above the icon
      tooltipRef.current.style.left = `${rect.left + window.scrollX + 10}px`; // Slightly to the right
    }
  }, [showTooltip]);

  return (
    <span className="relative inline-block ml-2">
      <Info
        ref={infoRef}
        className="text-blue-500 cursor-pointer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      {showTooltip && (
        <div
          ref={tooltipRef}
          className="fixed bg-white text-black p-4 rounded-lg shadow-lg z-50"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            maxWidth: "calc(100% - 20%)",
            maxHeight: "80vh",
            overflow: "auto",
            whiteSpace: "pre-wrap",
            transform: `translateY(10%) translateX(-7%)`, // Shift to the top-left
            borderRadius: "10px",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <h5 className="mb-3" style={{ fontWeight: "bold" }}>
            Calculation
          </h5>
          <div
            style={{
              overflowX: "auto",
              maxWidth: "100%",
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
              borderRadius: "10px",
            }}
          >
            {calculation}
          </div>
        </div>
      )}
    </span>
  );
};

export default Information;
