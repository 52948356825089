import { Formik } from "formik";
import PageCard from "../../../../atoms/PageCard";
import PageHeader from "../../../../atoms/PageHeader";
import SelectDropdown from "../../../../molecules/Dropdown";
import CustomButton from "../../../../atoms/CustomButton";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { getData, postData } from "../../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import FinancialDataTable from "./createReport";

import {
  auditStatusOptions,
  periodTypeOptions,
  sheetData,
} from "../../../../../helpers/staticData";

const AddFinancialData = () => {
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [formData, setFormData] = useState([]);
  const [fidata, setFidata] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [auditStatus, setAuditStatus] = useState();
  const [periodType, setPeriodType] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requiredAuditStatus, setRequiredAuditStatus] = useState([]);
  const [requiredPeriodType, setRequiredPeriodType] = useState([]);
  const [requiredSheetData, setRequiredSheetData] = useState([]);
  const [requiredYearData, setRequiredYearData] = useState([]);

  const sheetTypeValidation = Yup.mixed().test(
    "is-valid-sheetType",
    "At least one sheet type must be selected",
    (value) => {
      // If the value is an array, it must have at least one element
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      // If the value is a string, it must be a non-empty string
      return typeof value === "string" && value.trim().length > 0;
    },
  );

  const validationSchema = Yup.object().shape({
    company: Yup.string().required("Company is required"),
    auditStatus: Yup.string().required("Audit Status is required"),
    periodType: Yup.string().required("Period Type is required"),
    // sheetType: Yup.array()
    //   .of(Yup.string().required("Sheet Type is required"))
    //   .min(1, "At least one sheet type must be selected"),
    sheetType: sheetTypeValidation.required("Sheet Type is required"),
  });

  const getCompanyList = useCallback(async () => {
    try {
      const res = await getData({
        endpoint: "company/getCompanyList",
        token: cookies.t,
      });

      if (res) {
        setFormData(res?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [cookies.t]);

  useEffect(() => {
    getCompanyList();
  }, [getCompanyList]);

  const companyNameData = formData?.data?.map((company) => ({
    value: company._id,
    label: company.name,
    ...company,
  }));

  const initialValues = {
    company: "",
    auditStatus: "",
    periodType: "",
    sheetType: [], // Initialize as an array for multiselect
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);

    try {
      // const response = await getData({
      //   endpoint: "/company/getCompanyByIdWithFilteredData",
      //   params: {
      //     companyId: values.company,
      //     auditStatus: values.auditStatus,
      //     periodType: values.periodType,
      //     sheetType: JSON.stringify(values.sheetType),
      //     yearsArray: values.years,
      //   },
      //   token: cookies.t,
      // });
      const sheetTypesMapping = {
        balance_sheet: "balanceSheetData",
        pl: "plData",
      };

      const sheetTypeParams = Array.isArray(values.sheetType)
        ? values.sheetType
            .map((type) => sheetTypesMapping[type])
            .filter(Boolean)
        : [sheetTypesMapping[values.sheetType]];

      let response;

      // Check if periodType is "YTD"
      if (values.periodType === "YTD") {
        // Call the second API
        response = await getData({
          endpoint: "/company/getCompanyByIdListing",
          params: {
            companyId: values.company,
            auditStatus: values.auditStatus,
            sheetType: sheetTypeParams,
            // values.sheetType === "balance_sheet"
            //   ? "balanceSheetData"
            //   : "plData",
          },
          token: cookies.t,
        });

        if (response?.status) {
          toast.success("YTD Calculated Successfully", {
            style: confimationStyles,
            duration: 1000,
          });
        }
      } else {
        // Call the first API
        response = await getData({
          endpoint: "/company/getCompanyByIdWithFilteredData",
          params: {
            companyId: values.company,
            auditStatus: values.auditStatus,
            periodType: values.periodType,
            sheetType: JSON.stringify(values.sheetType),
            yearsArray: values.years,
          },
          token: cookies.t,
        });
      }
      // setFidata(response?.data);
      if (values?.periodType === "YTD") {
        setFidata(response);
      } else {
        setFidata(response?.data);
      }

      setAuditStatus(values.auditStatus);
      setPeriodType(values.periodType);
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error generating PDF:", error);
      // toast.error(
      //   "Please Try Again..An error occurred while generating the PDF",
      //   {
      //     style: errorStyles,
      //     duration: 2000,
      //   },
      // );
    } finally {
      setIsLoading(false);
    }
  };

  const handleAuditOptions = (option) => {
    const matchingPeriodOptions = auditStatusOptions.filter((item) =>
      option?.availableAuditStatuses?.includes(item.value),
    );

    const temp = matchingPeriodOptions.map((item) => ({
      ...item,
      data: option?.availableData[item?.value],
    }));
    // You can return or log the enhanced options depending on your use case
    setRequiredAuditStatus(temp);
  };

  const handlePeriodOption = (option) => {
    if (!option || !option.data) return; // Exit if option or option.data is not defined

    const dataKeys = Object.keys(option?.data);

    const matchingPeriodOptions = periodTypeOptions
      .filter((item) => dataKeys.includes(item.value))
      .map((item) => ({
        ...item,
        data: option.data[item.value], // Add the years array to each matching item
      }));

    setRequiredPeriodType(matchingPeriodOptions);
  };

  const handleYears = (option) => {
    const temp = option?.data?.map((item) => ({ value: item, label: item }));
    setRequiredYearData(temp);
  };

  const handleSheetData = async (option, values) => {
    if (
      values?.company &&
      values?.auditStatus &&
      values?.periodType &&
      option
    ) {
      const res = await getData({
        endpoint: "company/getAvailableSheetType",
        token: cookies.t,
        params: {
          companyId: values?.company,
          auditStatus: values?.auditStatus,
          periodType: values?.periodType,
          years: option,
        },
      });

      const temp = res?.data?.availableSheetType;

      const matching = sheetData.filter((item) => temp.includes(item?.value));

      setRequiredSheetData(matching);
    } else {
      setRequiredSheetData([]);
      return;
    }
  };

  return (
    <>
      <PageHeader title="Report" />
      <PageCard>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({
            isValid,
            dirty,
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
          }) => (
            <>
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flex: 1, marginRight: "10px" }}>
                  <label htmlFor="company">Company</label>
                  <SelectDropdown
                    data={companyNameData}
                    placeholder="Select Company"
                    onChange={(option) => {
                      setFieldValue("company", option?._id);
                      handleAuditOptions(option);
                    }}
                    isClearable={true}
                    format="all"
                    selectedValue={values.company}
                    onClear={() => {
                      setFieldValue("company", "");

                      setFieldValue("auditStatus", "");
                      setFieldValue("periodType", "");
                      setFieldValue("sheetType", []);
                      setRequiredPeriodType([]);
                      setRequiredSheetData([]);
                    }}
                  />
                  {touched.company && errors.company && (
                    <div style={{ color: "red", marginTop: "0.5rem" }}>
                      {errors.company}
                    </div>
                  )}
                </div>
                <div style={{ flex: 1, marginRight: "10px" }}>
                  <label htmlFor="auditStatus">Audit Status</label>
                  <SelectDropdown
                    data={requiredAuditStatus}
                    placeholder="Select Audit Status"
                    onChange={(option) => {
                      setFieldValue("auditStatus", option?.value);
                      handlePeriodOption(option);
                    }}
                    isClearable={true}
                    format={"all"}
                    selectedValue={values?.auditStatus}
                    onClear={() => {
                      setFieldValue("auditStatus", "");
                      setFieldValue("periodType", "");
                      setFieldValue("sheetType", []);
                      setRequiredPeriodType([]);
                      setRequiredSheetData([]);
                    }}
                  />
                  {touched.auditStatus && errors.auditStatus && (
                    <div style={{ color: "red", marginTop: "0.5rem" }}>
                      {errors.auditStatus}
                    </div>
                  )}
                </div>
                <div style={{ flex: 1, marginRight: "10px" }}>
                  <label htmlFor="periodType">Period Type</label>
                  <SelectDropdown
                    data={[
                      ...requiredPeriodType,
                      { label: "YTD", value: "YTD" },
                    ]}
                    placeholder="Select Period Type"
                    onChange={(option) => {
                      setFieldValue("periodType", option?.value);
                      handleYears(option);
                    }}
                    isClearable={true}
                    format="all"
                    selectedValue={values.periodType}
                    onClear={() => {
                      setFieldValue("periodType", "");
                      setFieldValue("years", []);
                      setFieldValue("sheetType", []);
                    }}
                  />
                  {touched.periodType && errors.periodType && (
                    <div style={{ color: "red", marginTop: "0.5rem" }}>
                      {errors.periodType}
                    </div>
                  )}
                </div>

                {values.periodType !== "YTD" && (
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    {/* <label htmlFor="Year">Year</label> */}
                    <label htmlFor="Year">
                      {!values.periodType && "Period"}
                      {values.periodType === "yearly" && "Year"}
                      {values.periodType === "quarterly" && "Quarter"}
                      {values.periodType === "monthly" && "Month"}
                      {values.periodType === "halfYearly" && "Half-Year"}
                    </label>
                    <SelectDropdown
                      data={requiredYearData}
                      // placeholder="Select Year"
                      placeholder={`Select ${
                        values.periodType === "yearly"
                          ? "Year"
                          : values.periodType === "quarterly"
                          ? "Quarter"
                          : values.periodType === "monthly"
                          ? "Month"
                          : values.periodType === "halfYearly"
                          ? "Half-Year"
                          : "Period"
                      }`}
                      onChange={(option) => {
                        setFieldValue("years", option);
                        handleSheetData(option, values);
                      }}
                      multiSelect
                      isClearable={true}
                      selectedValue={values.periodType}
                      onClear={() => {
                        setFieldValue("years", []);
                        setFieldValue("sheetType", []);
                      }}
                      onClose={false}
                    />
                    {touched.periodType && errors.periodType && (
                      <div style={{ color: "red", marginTop: "0.5rem" }}>
                        {errors.periodType}
                      </div>
                    )}
                  </div>
                )}

                <div style={{ flex: 1 }}>
                  <label htmlFor="periodType">Sheet Type</label>
                  <SelectDropdown
                    // data={requiredSheetData}
                    data={
                      values.periodType === "YTD"
                        ? [
                            { value: "balance_sheet", label: "Balance Sheet" },
                            { value: "pl", label: "P&L" },
                          ]
                        : requiredSheetData
                    }
                    placeholder="Select Sheet Type"
                    onChange={(values) => {
                      // Directly set the selected values (array) in Formik
                      setFieldValue("sheetType", values);
                    }}
                    isClearable={true}
                    selectedValue={values.sheetType} // Should be an array when multiselect is true
                    onClear={() => setFieldValue("sheetType", [])}
                    multiSelect={true} // Enable multiselect
                    onClose={false}
                    // multiSelect={values.periodType === "YTD" ? false : true} // Enable multiselect
                  />
                  {touched.sheetType && errors.sheetType && (
                    <div style={{ color: "red", marginTop: "0.5rem" }}>
                      {errors.sheetType}
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-4">
                <CustomButton
                  type="btn-primary"
                  iconRequired={false}
                  handleClick={handleSubmit}
                  disabled={isLoading || !isValid || !dirty}
                >
                  Submit
                  {isLoading && (
                    <div
                      className="spinner-border spinner-border-sm text-light ms-3"
                      role="status"
                    ></div>
                  )}
                </CustomButton>
              </div>
            </>
          )}
        </Formik>
      </PageCard>

      <div className="mt-4">
        {isSubmitted && (
          <PageCard>
            <FinancialDataTable
              data={fidata}
              auditStatus={auditStatus}
              periodType={periodType}
            />
          </PageCard>
        )}
      </div>
    </>
  );
};

export default AddFinancialData;
