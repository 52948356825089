import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense } from "react";
import AuthGuard from "./AuthGuard";
import GuestGuard from "./GuestGuard";

// Layouts
import AuthLayout from "../component/layouts/AuthLayout";
import DashboardLayout from "../component/layouts/DashboardLayout";

// import None from "../components/pages/none";

// Auth
import Login from "../component/pages/auth/login";
import Verification from "../component/pages/auth/verification";
import ResetOrCreatePass from "../component/pages/auth/resetOrCreatePass";
import ForgotPassword from "../component/pages/auth/forgotPassword";

// Dashboard
import Home from "../component/pages/dashboard/home";
import Settings from "../component/pages/dashboard/settings";
import Company from "../component/pages/dashboard/Company";
import None from "../component/pages/dashboard/None";
import AddCompany from "../component/pages/dashboard/Company/AddCompany/addCompany";
import ViewCompany from "../component/pages/dashboard/Company/AddCompany/viewCompany";
import DataTable from "../component/pages/dashboard/Company/AddCompany/viewFinancialData";
import FinancialData from "../component/pages/dashboard/FinancialData/uploadFinData";
import Report from "../component/pages/dashboard/Report/ReportGeneration/add";
import Edit from "../component/pages/dashboard/edit-profile/index";
import ChangePassword from "../component/pages/dashboard/edit-profile/change-password/index";

const routes = [
  {
    path: "/",
    element: <Navigate to="/auth" />,
  },
  {
    path: "auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        index: true, // Make this the index route
        element: <Login />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "verify",
        element: <Verification />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetOrCreatePass type="reset" />,
      },
      {
        path: "set-password",
        element: <ResetOrCreatePass type="create" />,
      },
    ],
  },
  {
    path: "dashboard/*",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
  },
  // {
  //   path: "none",
  //   element: <None />,
  // },
];

export const dashboardRoute = (
  <Suspense>
    <Routes>
      <Route
        // path="/"
        index
        element={
          <AuthGuard>
            <Home />
          </AuthGuard>
        }
      />
      <Route
        path="home"
        element={
          <AuthGuard>
            <Home />
          </AuthGuard>
        }
      />
      <Route
        path="/edit-profile"
        element={
          <AuthGuard>
            <Edit />
          </AuthGuard>
        }
      />

      <Route
        path="/edit-profile/change-password"
        element={
          <AuthGuard>
            <ChangePassword />
          </AuthGuard>
        }
      />

      <Route
        path="company"
        element={
          <AuthGuard>
            <Company />
          </AuthGuard>
        }
      />
      <Route
        path="company/add"
        element={
          <AuthGuard>
            <AddCompany />
          </AuthGuard>
        }
      />
      <Route
        path="company/edit/:id"
        element={
          <AuthGuard>
            <AddCompany type="edit" />
          </AuthGuard>
        }
      />
      <Route
        path="company/view/:id"
        element={
          <AuthGuard>
            <ViewCompany />
          </AuthGuard>
        }
      />
      <Route
        path="company/table/:id"
        element={
          <AuthGuard>
            <DataTable />
          </AuthGuard>
        }
      />
      <Route
        path="setting"
        element={
          <AuthGuard>
            <Settings />
          </AuthGuard>
        }
      />
      <Route
        path="company/financialData"
        element={
          <AuthGuard>
            <FinancialData />
          </AuthGuard>
        }
      />
      <Route
        path="report"
        element={
          <AuthGuard>
            <Report />
          </AuthGuard>
        }
      />
      <Route
        path="none"
        element={
          <AuthGuard>
            <None />
          </AuthGuard>
        }
      />
    </Routes>
  </Suspense>
);

export default routes;
