import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  customDropdownStyles,
  upperDropdownStyles,
  dashBoardStyles,
  errorStyles,
} from "./dropdown";
import CustomOption from "./CustomOption"; // Import the CustomOption component

const SelectDropdown = ({
  id,
  multiSelect = false,
  selectedValue,
  onChange,
  classes,
  placeholder,
  data,
  format,
  isDisabled,
  isClearable,
  type,
  onClear,
  menuPlacement = "bottom",
  isError,
  onClose = true,
  handleBlur = () => {},
  removeCallback, // Prop to handle option removal
  isRemoveOption = false,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(null);

  useEffect(() => {
    if (multiSelect && selectedValue) {
      const initialSelectedOptions = data?.filter((option) =>
        selectedValue.includes(option.value),
      );
      setSelectedOptions(initialSelectedOptions);
    } else if (!multiSelect && selectedValue != null) {
      const initialSelectedOption =
        data?.find((option) => option.value === selectedValue) || null;
      setSelectedOptions(initialSelectedOption);
    } else {
      setSelectedOptions(multiSelect ? [] : null);
    }
  }, [selectedValue, multiSelect, data]);

  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);

    if (multiSelect) {
      if (selected.length === 0) {
        onClear();
      } else {
        const selectedValues = selected.map((option) => option.value);
        onChange(selectedValues);
      }
    } else {
      if (!selected) {
        onClear();
      }
      if (format === "all") {
        onChange(selected);
      } else {
        onChange(selected?.value);
      }
    }
  };

  const handleRemoveOption = (value) => {
    const updatedOptions = selectedOptions?.filter(
      (option) => option?.value !== value,
    );
    setSelectedOptions(updatedOptions);

    // Notify the parent about the removed option
    if (removeCallback) {
      removeCallback(value);
    }

    if (multiSelect) {
      const selectedValues = updatedOptions.map((option) => option.value);
      onChange(selectedValues);
    } else {
      onChange(null);
      onClear();
    }
  };

  const styles = isError
    ? { ...customDropdownStyles, ...errorStyles }
    : customDropdownStyles;

  return (
    <Select
      className={`basic-single ${classes}`}
      classNamePrefix="select"
      options={data}
      value={selectedOptions}
      isMulti={multiSelect}
      onChange={handleSelectChange}
      onBlur={handleBlur}
      styles={styles}
      key={id}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isClearable={isClearable}
      menuPlacement={menuPlacement}
      closeMenuOnSelect={onClose}
      components={{
        Option: (props) => (
          <CustomOption
            {...props}
            removeCallback={isRemoveOption ? handleRemoveOption : null}
          />
        ),
      }}
    />
  );
};

export default SelectDropdown;
