const parseDate = (dateStr) => {
  const monthMap = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  let month, year;

  // Normalize dateStr
  dateStr = dateStr.trim().toLowerCase();

  // Check for year only
  if (/^\d{4}$/.test(dateStr)) {
    return new Date(dateStr, 0); // Default to January of that year
  }

  // Check for month and year in formats
  const [monthPart, yearPart] = dateStr.split(/\s+/);

  // Handle month abbreviation or full name
  month = monthMap[monthPart.charAt(0).toUpperCase() + monthPart.slice(1)];
  if (month === undefined) {
    throw new Error(`Invalid month name: ${monthPart}`);
  }

  // Handle year part
  if (yearPart) {
    year = yearPart.replace(/'/, "20"); // Handle `'23` as `2023`
    if (year.length === 2) year = "20" + year;
  } else {
    year = new Date().getFullYear(); // Default to current year if no year provided
  }

  return new Date(year, month);
};

export const sortObjectByDateKeys = (obj) => {
  try {
    // Extract and parse the keys
    const sortedKeys = Object.keys(obj).sort((a, b) => {
      const dateA = parseDate(b);
      const dateB = parseDate(a);
      return dateA - dateB;
    });

    // Create a new object with sorted keys
    const sortedObj = {};
    sortedKeys.forEach((key) => {
      sortedObj[` ${key}`] = obj[key];
    });

    return sortedObj;
  } catch (error) {
    console.error("Error while sorting:", error);
    // Return the original object if sorting fails
    return obj;
  }
};
