import { useCallback, useEffect, useState } from "react";
import CustomButton from "../../../atoms/CustomButton";
import PageCard from "../../../atoms/PageCard";
import PageHeader from "../../../atoms/PageHeader";
import Delete from "../../../icons/Delete";
import Edit from "../../../icons/Edit";
import View from "../../../icons/View";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import Search from "../../../molecules/search";
import { v4 as uuidv4 } from "uuid";
import Modal from "../../../organisms/modals/Modal";
import Form from "./AddCompany/addCompany";
import Pagination from "../../../molecules/Pagination";
import DeleteModal from "../../../organisms/modals/DeleteModal";
import { useNavigate } from "react-router-dom";
import { deleteData, getData } from "../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../assets/styles/toast";
import { errorStyles } from "../../../molecules/Dropdown/dropdown";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import { capitalize } from "../../../../helpers/capitalize";
import NoContentIcon from "../../../icons/NocontentIcon";

const Employee = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t"]);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalData: null,
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });

  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const getUserData = useCallback(async () => {
    try {
      // setLoading(true);
      const res = await getData({
        endpoint: "company/getAllCompanies",
        params: {
          search_query: searchedValue,
          page: currentPage,
          page_limit: itemsPerPage,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res?.data);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.t, currentPage, itemsPerPage]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const totalItems = tableData?.total_record;

  const handleEdit = (id) => {
    navigate(`/dashboard/company/edit/${id}`);
  };
  const handleView = (id) => {
    navigate(`/dashboard/company/view/${id}`);
  };
  const handleTable = (id) => {
    navigate(`/dashboard/company/table/${id}`);
  };

  const handleDelete = (id, name) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: id,
      name: name,
    }));
  };

  const handleDeleteConfirm = async (id) => {
    try {
      // Add your delete logic here, for example:
      const res = await deleteData({
        endpoint: `company/deleteCompanyById`,
        params: {
          companyId: id,
        },
        token: cookies.t,
      });
      if (res?.data?.status) {
        toast.success("Company Deleted Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
      } else {
        toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      }
      // Refresh the data after deletion
      getUserData();
    } catch (error) {
      console.error(error);
    } finally {
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleMenuItemClick = (action) => {
    if (selectedRow) {
      if (action === "viewCompany") {
        handleView(selectedRow._id, "view");
      } else if (action === "viewFinancialData") {
        handleTable(selectedRow._id);
      }
    }
    handleMenuClose();
  };

  const columns = [
    {
      field: "name",
      headerName: "Company Name",
      flex: 1,
      // sortable: true,
      renderCell: (params) => capitalize(params.value),
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      // sortable: true,
      renderCell: (params) => capitalize(params.value),
    },
    {
      field: "domain",
      headerName: "Domain",
      flex: 1,
      // sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      // sortable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      // sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span
              className="me-2 pointer"
              onClick={() => handleEdit(params.row._id)}
            >
              <Edit />
            </span>
            <span
              className="me-2 pointer"
              onClick={() => handleDelete(params.row._id, params.row.name)}
            >
              <Delete color="black" />
            </span>
            <span
              onClick={(event) => handleMenuOpen(event, params.row)}
              className="me-2 pointer"
            >
              <GridMoreVertIcon />
            </span>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleMenuItemClick("viewCompany")}>
                Company Details
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItemClick("viewFinancialData")}
              >
                Financial Data
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const openModal = (title, data) => {
    const modalId = uuidv4();
    setModalState((prevState) => ({
      ...prevState,
      modalId,
      isModalOpen: true,
      title,
      modalData: data,
    }));
  };
  const closeModal = () => {
    setModalState((prevState) => ({ ...prevState, isModalOpen: false }));
  };

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data.name,
    }));
  };

  return (
    <>
      <PageHeader title="Companies" type="small" />
      <PageCard>
        <div className="d-flex space-between">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />
          <div className="d-flex gap-3">
            <CustomButton
              type="btn-primary"
              text="Add Financial Data"
              handleClick={() => navigate("financialData")}
            />
            <CustomButton
              type="btn-primary"
              text="Add Company"
              handleClick={() => navigate("add")}
            />
          </div>
        </div>

        {tableData?.data && tableData.data.length > 0 ? (
          <>
            <CustomDatagrid
              getRowId={(row) => row._id}
              rows={tableData?.data || []}
              columns={columns}
              tHeight="50vh"
            />

            {totalItems > 10 && (
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <div>
              <NoContentIcon />
              <div
                className="graph-heading"
                style={{
                  fontSize: "18px",
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                No Records Found
              </div>
            </div>
          </div>
        )}
      </PageCard>

      {modalState?.isModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isModalOpen}
          onClose={closeModal}
          title={modalState.title}
          extrastyle={{
            overflow: "visible",
            cssText: "overflow: visible !important",
          }}
          width="600px"
        >
          <Form
            onClose={closeModal}
            id={modalState.modalId}
            initialValues={
              modalState.title === "Edit Location" ? modalState.modalData : null
            }
            // refetchData={getUserData}
          />
        </Modal>
      )}
      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          // height="300px"
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Company"
          onClick={() => handleDeleteConfirm(modalState.modalId)}
        />
      )}
    </>
  );
};
export default Employee;
