export const statusData = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

export const userRoles = [
  { label: "Admin ", value: "Admin" },
  { label: "Manager", value: "Manager" },
  { label: "Viewer", value: "Viewer" },
];

export const businessTypes = [
  { value: "Private Limited", label: "Private Limited" },
  { value: "Public Limited", label: "Public Limited" },
  { value: "Sole Proprietorship", label: "Sole Proprietorship" },
  { value: "Individual", label: "Individual" },
  { value: "Partnership", label: "Partnership" },
  { value: "Limited Liability Company", label: "Limited Liability Company" },
  {
    value: "Limited Liability Partnership",
    label: "Limited Liability Partnership",
  },
  { value: "Corporation", label: "Corporation" },
  {
    value: "Non-Government Organization",
    label: "Non-Government Organization",
  },
];

export const formType = [
  { value: "MultipleChoice", label: "Multiple choice" },
  { value: "Checkboxes", label: "Checkboxes" },
  { value: "DropDown", label: "Drop-Down" },
];

export const auditStatusOptions = [
  { value: "audited", label: "Audited" },
  { value: "unaudited", label: "Unaudited" },
];

export const periodTypeOptions = [
  { value: "yearly", label: "Yearly" },
  { value: "halfYearly", label: "Half-Yearly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "monthly", label: "Monthly" },
];

export const sheetData = [
  { value: "balance_sheet", label: "Balance Sheet" },
  { value: "pl", label: "P&L" },
  { value: "other", label: "Other" },
];