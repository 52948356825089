const ArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M14.0326 6.1574C13.9629 6.0871 13.8799 6.0313 13.7885 5.99323C13.6971 5.95515 13.5991 5.93555 13.5001 5.93555C13.4011 5.93555 13.3031 5.95515 13.2117 5.99323C13.1203 6.0313 13.0373 6.0871 12.9676 6.1574L9.53261 9.59239C9.46288 9.66268 9.37993 9.71848 9.28854 9.75656C9.19714 9.79463 9.09912 9.81424 9.00011 9.81424C8.9011 9.81424 8.80307 9.79463 8.71167 9.75656C8.62028 9.71848 8.53733 9.66268 8.46761 9.59239L5.03261 6.1574C4.96288 6.0871 4.87993 6.0313 4.78854 5.99323C4.69714 5.95515 4.59912 5.93555 4.50011 5.93555C4.4011 5.93555 4.30307 5.95515 4.21167 5.99323C4.12028 6.0313 4.03733 6.0871 3.96761 6.1574C3.82792 6.29792 3.74951 6.48801 3.74951 6.68614C3.74951 6.88428 3.82792 7.07437 3.96761 7.21489L7.41011 10.6574C7.83198 11.0787 8.40385 11.3154 9.00011 11.3154C9.59636 11.3154 10.1682 11.0787 10.5901 10.6574L14.0326 7.21489C14.1723 7.07437 14.2507 6.88428 14.2507 6.68614C14.2507 6.48801 14.1723 6.29792 14.0326 6.1574Z"
        fill="#6F767E"
      />
    </svg>
  );
};
export default ArrowDown;
