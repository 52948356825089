import React from "react";
import Invalid from "../../../assets/images/invalid.png";

const Error = ({ error, type = "" }) => {
  return (
    <div
      className="error-div d-flex"
      style={{ fontSize: type === "issue" ? "16px" : "" }}
    >
      <img src={Invalid} height={type === "issue" ? 20 : 16} className="me-2" />
      {error}
    </div>
  );
};

export const BlackError = ({ error }) => {
  return (
    <div className="black-error-div d-flex">
      <img src={Invalid} height={16} className="me-2" />
      {error}
    </div>
  );
};

export default Error;
