import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./styles.scss";
import { deleteData, getData, postData } from "../../../services/index";
import { useCookies } from "react-cookie";
import CustomButton from "../../../component/atoms/CustomButton/index";
import SelectDropdown from "../../molecules/Dropdown";
import Add from "../../icons/Add";
import Modal from "../modals/Modal";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../assets/styles/toast";
import { errorStyles } from "../../molecules/Dropdown/dropdown";

const CustomPrompt = ({ data, preview, sheetType, metric, inferenceOnly }) => {
  const [prompts, setPrompts] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [cookies] = useCookies(["t"]);
  const [newPrompt, setNewPrompt] = useState("");
  const [predefinedPrompts, setPredefinedPrompts] = useState([]);
  const [currentPromptId, setCurrentPromptId] = useState(null);
  const [removedOption, setRemovedOption] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const getPromptList = useCallback(async () => {
    try {
      const res = await getData({
        params: { sheetType },
        endpoint: "prompt/getPromptList",
        token: cookies.t,
      });

      // if (res) {
      //   console.log("mappedPrompts->", res?.data?.data);
      // }

      if (res && res?.data && Array.isArray(res?.data.data)) {
        const mappedPrompts = res?.data?.data?.map((prompt) => ({
          label: prompt.prompt,
          value: prompt.prompt,
        }));

        setPredefinedPrompts(mappedPrompts);
      }
    } catch (error) {
      console.error(error);
    }
  }, [cookies.t]);

  useEffect(() => {
    getPromptList();
  }, [getPromptList]);

  useEffect(() => {
    const initialPrompts = [];

    if (sheetType === "bsGraph" || sheetType === "plGraph") {
      const initialPrompt = {
        id: Date.now(),
        value: `Give me inference of ${metric?.value || ""}`,
        output: "",
        copied: false,
        selectedOption: null,
      };
      initialPrompts.push(initialPrompt);

      handleGenerateInference(initialPrompt.id, initialPrompt.value);
    } else {
      const initialPrompt = {
        id: Date.now(),
        value: getInitialPromptValue(),
        output: "",
        copied: false,
        selectedOption: getInitialPromptSelection(),
      };
      initialPrompts.push(initialPrompt);
      if (initialPrompt.value) {
        handleGenerateInference(initialPrompt.id, initialPrompt.value);
      }
    }

    setPrompts(initialPrompts);
  }, [sheetType, data, metric]);

  const getInitialPromptValue = () => {
    switch (sheetType) {
      case "balanceSheet":
        return "Give me inference of balance sheet data";
      case "plSheet":
        return "Give me inference of profit and loss data";
      case "general":
        return "Give me general inference of financial data";
      default:
        return "";
    }
  };

  const getInitialPromptSelection = () => {
    switch (sheetType) {
      case "balanceSheet":
        return predefinedPrompts.find(
          (p) => p.value === "Give me inference of balance sheet data",
        );
      case "plSheet":
        return predefinedPrompts.find(
          (p) => p.value === "Give me inference of profit and loss data",
        );
      case "general":
        return predefinedPrompts.find(
          (p) => p.value === "Give me general inference of financial data",
        );
      case "bsGraph":
      case "plGraph":
        return predefinedPrompts.find(
          (p) => p.value === "Give me inference of selected metric",
        );
      default:
        return null;
    }
  };

  const handleAddPrompt = (defaultValue = "", autoGenerate = false) => {
    const newPrompt = {
      id: Date.now(),
      value: defaultValue,
      output: "",
      copied: false,
      selectedOption: null,
    };
    setPrompts((prevPrompts) => {
      const updatedPrompts = [...prevPrompts, newPrompt];
      if (autoGenerate) {
        handleGenerateInference(newPrompt.id, defaultValue);
      }
      return updatedPrompts;
    });
  };

  const handleRemovePrompt = (id) => {
    setPrompts((prompts) =>
      prompts.length > 1
        ? prompts.filter((prompt) => prompt.id !== id)
        : prompts,
    );
  };

  const handlePromptChange = (id, newValue) => {
    setPrompts((prompts) =>
      prompts.map((prompt) =>
        prompt.id === id ? { ...prompt, value: newValue } : prompt,
      ),
    );
  };

  const handleOutputChange = (id, newOutput) => {
    setPrompts((prompts) =>
      prompts.map((prompt) =>
        prompt.id === id ? { ...prompt, output: newOutput } : prompt,
      ),
    );
  };

  const handleGenerateInference = async (id, defaultValue = "") => {
    try {
      setIsSaving(true);
      setCurrentPromptId(id); // Store the current prompt ID

      const prompt = prompts.find((prompt) => prompt.id === id) || {
        value: defaultValue,
      };

      let dataToSend = {};
      switch (sheetType) {
        case "balanceSheet":
          dataToSend.balanceSheetData = data?.balanceSheet;
          break;
        case "plSheet":
          dataToSend.plData = data?.plData;
          break;
        case "bsGraph":
          dataToSend.balanceSheetData = data?.balanceSheet;
          break;
        case "plGraph":
          dataToSend.plData = data?.plData;
          break;
        case "general":
        default:
          dataToSend.balanceSheetData = data?.balanceSheet;
          dataToSend.plData = data?.plData;
          break;
      }

      const selectedOption = prompts.find((p) => p.id === id)?.selectedOption;

      const inputString = prompt.value
        ? `${prompt.value} with currency and units`
        : `${
            selectedOption ? selectedOption : "No selected option"
          } with currency and units`;

      const resultData = await postData({
        endpoint: "extract/generate-inference",
        data: {
          input: inputString,
          data: JSON.stringify(dataToSend),
        },
        token: cookies.t,
      });

      setPrompts((prompts) =>
        prompts.map((p) =>
          p.id === id ? { ...p, output: resultData?.data?.inference } : p,
        ),
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
      setCurrentPromptId(null); // Reset after saving
    }
  };

  const handleDropdownChange = (id, option) => {
    const selectedValue = option ? option.label : "";
    handlePromptChange(id, selectedValue);
    setPrompts((prompts) =>
      prompts.map((prompt) =>
        prompt.id === id ? { ...prompt, selectedOption: option } : prompt,
      ),
    );
  };

  const handleAddNewPrompt = async () => {
    try {
      if (newPrompt) {
        const res = await postData({
          endpoint: "prompt/addPrompt",
          data: {
            sheetType,
            prompt: newPrompt,
          },
          token: cookies.t,
        });
        setNewPrompt("");
        handleCloseModal();
        getPromptList();

        if (res) {
          toast.success("Prompt Added Successfully", {
            style: confimationStyles,
            duration: 1000,
          });
        } else {
          toast.error(res?.data?.message, {
            style: errorStyles,
            duration: 1000,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getHeadingText = () => {
    switch (sheetType) {
      case "balanceSheet":
        return "Balance Sheet Inference";
      case "plSheet":
        return "Profit and Loss Inference";
      case "general":
        return "Overall Inference";
      default:
        return "Inference";
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRemoveOption = async (value) => {
    try {
      // Add your delete logic here, for example:
      const res = await deleteData({
        endpoint: `prompt/deletePrompt`,
        params: {
          sheetType: sheetType,
          prompt: value,
        },
        token: cookies.t,
      });

      if (res) {
        toast.success("Prompt Deleted Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
      } else {
        toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      }
      // Refresh the data after deletion
      getPromptList();
    } catch (error) {
      console.error(error);
    }
  };

  const shouldShowDropdown = sheetType !== "bsGraph" && sheetType !== "plGraph";

  return (
    <div className="financial-data-table">
      {preview && <div className="section-heading">Custom Prompts</div>}
      {!preview && <h3 className="section-heading">{getHeadingText()}</h3>}
      {sheetType === "bsGraph" || sheetType === "balanceSheet" ? (
        <h1 className="section2-heading">
          (<b>Data Source</b> : Balance Sheet)
        </h1>
      ) : sheetType === "plGraph" || sheetType === "plSheet" ? (
        <h1 className="section2-heading">
          (<b>Data Source</b> : Profit & Loss)
        </h1>
      ) : (
        <h1 className="section2-heading">
          {/* (<b>Data Source</b> : Balance Sheet and Profit & Loss)  */}(
          <b>Data Source</b> : All Data)
        </h1>
      )}
      <div className={`prompts-container ${!preview ? "preview" : ""}`}>
        {prompts.map((prompt) => (
          <div key={prompt.id} className="prompt-item">
            {preview && (
              <>
                <strong>Prompt</strong>
                <div style={{ marginTop: "10px" }}></div>
                {shouldShowDropdown && (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <SelectDropdown
                        data={predefinedPrompts}
                        placeholder="Select a predefined prompt"
                        onChange={(option) => {
                          handleDropdownChange(prompt.id, option);
                        }}
                        selectedValue={prompt.selectedOption || null}
                        removeCallback={handleRemoveOption}
                        isClearable={true}
                        onClear={() => handlePromptChange(prompt.id, "")}
                        classes={`w-100`}
                        isRemoveOption={true}
                      />
                      <CustomButton
                        iconRequired={false}
                        type="btn-primary"
                        buttonType="submit"
                        handleClick={handleOpenModal}
                        style={{ marginLeft: "10px" }}
                      >
                        <Add />
                      </CustomButton>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontStyle: "italic",
                          fontSize: "16px",
                        }}
                      >
                        or
                      </span>
                    </div>
                  </>
                )}

                <input
                  type="text"
                  value={prompt.value}
                  onChange={(e) =>
                    handlePromptChange(prompt.id, e.target.value)
                  }
                  className="prompt-input"
                  placeholder="Enter your prompt"
                  style={{ marginBottom: "20px", marginTop: "10px" }}
                />
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleGenerateInference(prompt.id)}
                  disabled={isSaving && currentPromptId === prompt.id}
                  style={{ width: "fit-content", marginBottom: "20px" }}
                >
                  Generate Inference
                  {isSaving && currentPromptId === prompt.id && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
                <strong>Output</strong>
              </>
            )}
            {preview && (
              <div className="output-box">
                <textarea
                  value={prompt.output}
                  onChange={(e) =>
                    handleOutputChange(prompt.id, e.target.value)
                  }
                  className="output-textarea"
                  placeholder="Inference result will appear here"
                />
              </div>
            )}
            {!preview && (
              <div
                contentEditable={true}
                style={{
                  whiteSpace: "pre-wrap",
                  marginTop: "10px",
                  fontWeight: 500,
                  backgroundColor: "whitesmoke",
                  padding: "10px",
                  borderRadius: "5px",
                  outline: "none",
                  border: "none",
                }}
                className="no-page-break"
              >
                {prompt.output}
              </div>
            )}
            {preview && (
              <>
                {prompts.length > 1 && (
                  <CustomButton
                    iconRequired={false}
                    type="btn-primary"
                    buttonType="submit"
                    handleClick={() => handleRemovePrompt(prompt.id)}
                    style={{ width: "fit-content", marginTop: "20px" }}
                  >
                    Remove
                  </CustomButton>
                )}
              </>
            )}
          </div>
        ))}
        {preview && (
          <CustomButton
            iconRequired={false}
            type="btn-primary"
            buttonType="submit"
            handleClick={() => handleAddPrompt()}
          >
            Add Prompts
          </CustomButton>
        )}
      </div>

      {isModalOpen && (
        <Modal
          id="prompt-modal"
          title="Add New Prompt"
          isActive={isModalOpen}
          onClose={handleCloseModal}
          width="600px"
          bodyStyles={{ padding: "20px" }}
          // maxHeight="200px"
        >
          <input
            type="text"
            value={newPrompt}
            onChange={(e) => setNewPrompt(e.target.value)}
            placeholder="Enter new prompt"
            style={{ width: "100%", marginBottom: "10px" }}
          />
          <CustomButton
            iconRequired={false}
            type="btn-primary"
            buttonType="submit"
            handleClick={handleAddNewPrompt}
            // disabled={isAdding}
          >
            {/* {isSaving ? "Saving..." : "Save"} */}
            Save
          </CustomButton>
        </Modal>
      )}
    </div>
  );
};

export default CustomPrompt;
